export default {

    notify: {
        type: "",
        title: ""
    },
    loading: {
        status: false,
        mes: ""
    },
    topBarTips: {
        title: "",
        content: ""
    },
    unreadNum: 0,
    brandName: localStorage.getItem("brandName") || "账户设置",
    topBarPath: []
}