<template>
  <div class="wrapper flex-center-center">
    <main class="flex-center-center">
      <img src="https://s1.ax1x.com/2020/04/14/Gxf32F.jpg" alt="" srcset="" />
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      homeTagList: []
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    this.$store.commit("SET_TOPBARPATH", [
      { name: "通用设置", path: "/setImage" },
      { name: "账号管理", path: "/other" }
    ]);
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
main {
  width: 1000px;
  min-height: 90vh;
  background: #fff;
}
</style> 