<template>
  <div class="wrapper flex-col-center-center">
    <div v-show="false" id="qrcode"></div>
    <main class="flex-center-center">
      <img src="https://s1.ax1x.com/2020/04/14/Gxf32F.jpg" alt="" srcset="" />
    </main>
  </div>
</template> 


<script>
import QRCode from "QRCode";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    getQrcode() {
      var that = this;
      this.$loading(true);
      setTimeout(function () {
        that.$loading(false);
      }, 500);
      var qrcode = ""; // eslint-disable-line no-unused-vars
      qrcode = new QRCode(document.getElementById("qrcode"), {
        text: "weixin://dl/business/?t=qk97JqWmlZc",
        width: 300,
        height: 300,
        codeWidth: 200,
        codeHeight: 200,
        top: 55,
        left: 55,
        colorDark: "#ffffff",
        colorLight: "#000000",
        correctLevel: QRCode.CorrectLevel.L,
        materials: {
          eye: require("@/assets/qrcode/eye.png"),
          row4: require("@/assets/qrcode/row4.png"),
          col4: require("@/assets/qrcode/col3.png"),
          row2col3: require("@/assets/qrcode/row2col3.png"),
          row3col2: require("@/assets/qrcode/row3col2.png"),
          col3: require("@/assets/qrcode/col3.png"),

          row2col2: require("@/assets/qrcode/row2col2.png"),
          corner: require("@/assets/qrcode/corner.png"),
          row2: require("@/assets/qrcode/row2.png"),
          col2: require("@/assets/qrcode/col2.png"),
          single: require("@/assets/qrcode/single.png"),
        },
      });
    },

    // qrcode = new QRCode(document.getElementById("qrcode"), {
    //         text: "https://www.lilnong.top/cors/",
    //         height: 500,
    //         codeWidth: 320,
    //         codeHeight: 320,
    //         top: 85,
    //         left: 80,
    //         materials: {
    //             border: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_border.png",
    //             eye: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_eye.png",
    //             row4: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_row4.png",
    //             col4: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_col3.png",
    //             row2col3: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_row2col3.png",
    //             row3col2: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_row3col2.png",
    //             col3: ["https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_col3.png",
    // "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_col3_2.png"],
    //             row2col2: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_row2col2.png",
    //             corner: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_corner.png",
    //             row2: ["https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_row2.png",
    // "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_row2_2.png"],
    //             col2: "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_col2.png",
    //             single: ["https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_single.png",、
    //  "https://www.lilnong.top/static/img/yanshi22345/http___www.jq22.com_demo_erwm201910121055_materials_electron_single_2.png"],

    //         }
    //     });
  },
  created() {},
  mounted() {
    this.getQrcode();
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.title {
  color: #fff;
}
</style> 