<template>
  <home-tag-list :type="'list'"></home-tag-list>
</template>

<script>
import HomeTagList from "@/components/custom/HomeTagList.vue";

export default {
  components: { HomeTagList },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
</style>