<template>
  <media-box></media-box>
</template>

<script>
import MediaBox from "@/components/custom/MediaBox.vue";

export default {
  components: { MediaBox },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
</style>