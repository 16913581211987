import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

// 按需引入Element Ui
import {
  Notification,
  Form,
  Input,
  FormItem,
  Option,
  Select,
  Popover,
  Button,
  MessageBox,
  Dialog,
  Cascader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  Switch,
  InputNumber,
  Autocomplete,
  Loading,
  Slider,
  Radio,
  RadioGroup,
  RadioButton,
  Pagination,
  Checkbox,
  Tooltip,
  CheckboxGroup,
  Alert,
  Popconfirm,
} from "element-ui";
import "@/assets/theme/index.css";
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Option);
Vue.use(Select);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(Cascader);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Switch);
Vue.use(Autocomplete);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(InputNumber);
Vue.use(Pagination);
Vue.use(Slider);
Vue.use(Checkbox);
Vue.use(Tooltip);
Vue.use(CheckboxGroup);
Vue.use(Alert);
Vue.use(Popconfirm);

Vue.use(Loading.directive);

// 注册到全局

Vue.prototype.$eleNotify = Notification;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$loading = Loading.service;

/* 全局提示框 */
import notifys from "@/util/notify"; //这里引入的是js文件
Vue.prototype.$notifys = notifys;
var notifyPromise = Promise.resolve();

function notify({ type, title }) {
  notifyPromise = notifyPromise.then(this.$nextTick).then(() => {
    this.$notifys({
      type: type,
      title: title,
    });
  });
}
Vue.prototype.$notify = notify;

/* 全局请求axios */
import api from "@/axios/api"; //这里引入的是js文件
Vue.prototype.$api = api;

/* 全局请求axios */
import aliyun from "@/axios/aliyun"; //这里引入的是js文件
Vue.prototype.$aliyun = aliyun;

/* 全局工具 */
import checkStr from "@/util/checkStr.js"; //这里引入的是js文件
Vue.prototype.$checkStr = checkStr;

import util from "@/util/index.js"; //这里引入的是js文件
Vue.prototype.$util = util;

/* 全局请求axios */
import oss from "@/axios/oss"; //这里引入的是js文件
Vue.prototype.$oss = oss;

// 全局请求加载动画

Vue.prototype.$loading = function (status, mes) {
  store.commit("SET_LOADING", {
    status: status,
    mes: mes || "",
  });
};

/* 引入全局CSS */
import "@/assets/css/reset.css"; // 重置样式
import "@/assets/css/global.css"; // 全局样式
import "@/assets/css/compatible.css"; // 响应式兼容

// 路由登录拦截
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const token = localStorage.getItem("token");
  if (!to.meta.pathName) {
    // 验证是否需要登陆 不需要登录
    if (token) {
      // 查询本地存储信息是否已经登陆
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    // 需要登录的页面
    if (!token && to.path !== "/login") {
      // 查询本地存储信息是否已经登陆
      next({
        path: "/login",
        query: { redirect: to.fullPath }, // 将要跳转路由的path作为参数，传递到登录页面
      });
    } else {
      next();
    }
  }
});

const errorHandler = (error, vm) => {
  store.commit("SHOW_NOTIFY", {
    type: "error",
    title: "发生错误：" + error,
  });
  console.log(vm);
  console.log(error);
};

Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error) => errorHandler(error, this);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
