/**
 * 全局的通知框
 * @param {type,title,cont}
 * 如果没有cont则不用传入
 */

export default function(params) {
    let that = this
    let _type = params.type
    let _title = params.title
    let _cont = ""
    if (params.cont) {
        _cont = params.cont
    }
    let _icon = "lefticon-" + _type
    return that.$eleNotify({
        message: "<div><div class='title'><svg class='icon " + _icon + "'><use xlink:href='#" + _icon + "'></use></svg><div>" + _title + "</div></div><div class='cont'> " + _cont + "</div></div>",
        dangerouslyUseHTMLString: true, // 最好不要动
        duration: 5000, // 弹框延时时间
    });
}