<template>
  <div class="wrapper">
    <main>
      <img
        class="logo"
        :src="require('@/assets/images/logo.png')"
        alt="这是Logo"
        title="LOGO"
      />
      <div class="title">登录</div>
      <div class="le-form-item">
        <div class="le-form-title">账号</div>
        <div class="le-form-main">
          <el-input v-model="form.username"></el-input>
        </div>
      </div>
      <div class="le-form-item">
        <div class="le-form-title">
          密码
        </div>
        <div class="le-form-main">
          <el-input type="password" v-model="form.password"></el-input>
        </div>
      </div>

      <div class="button-box">
        <button @click="login" class="le-button">登录</button>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    login() {
      localStorage.removeItem("token");
      this.$api
        .POST("/login", {
          username: this.form.username,
          password: this.form.password
        })
        .then(res => {
          if (res.code == 200) {
            // 存储用户token
            localStorage.setItem("token", JSON.stringify(res.data.token));
            this.$router.push("/");
          } else {
            this.$notify({
              type: "error",
              title: "服务器暂停使用"
            });
          }
        });
    }
  },
  created() {
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.login(); // 登录方法名
        return false;
      }
    };
  },
  beforeDestroy() {
    document.onkeypress = null;
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

main {
  max-width: 600px;
  width: 600px;
  min-height: 300px;
  position: relative;
}

.logo {
  position: absolute;
  top: -100px;
  width: 100px;
}
.title {
  font-size: 32px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 50px;
}
.button-box {
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button-box button {
  margin-left: 20px;
}
.authCode-box {
  margin-left: 30px;
  display: flex;
  align-items: flex-end;
}
.authCode-box span {
  color: #707070;
}
.authCode-img {
  width: 64px;
  margin-right: 10px;
  height: 31px;
  border: 2px #000 solid;
}
.authCode-img img {
  width: 100%;
  height: 100%;
}
</style>