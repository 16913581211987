

<template>
  <div class="wrapper flex-center-center">
    <article-edit :id="$route.params.id"></article-edit>
  </div>
</template>

<script>
import ArticleEdit from "@/components/custom/ArticleEdit.vue";
export default {
  components: { ArticleEdit },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    this.$store.commit("SET_TOPBARPATH", [
      { name: "文章管理", path: "/Article" },
      { name: "更新文章", path: "/UpdatedArticle" }
    ]);
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
</style> 