<template>
  <div class="header">
    <div class="left-path">
      <div
        v-for="(item, index) in pathArr"
        :key="'path-' + index"
        @click="toPath(item.path)"
        :style="pathArr.length - 1 == index ? 'color:#000' : ''"
      >
        {{ item.name }}
        <span class="xgicon" v-if="index != pathArr.length - 1">></span>
      </div>
    </div>
    <div class="right">
      <div class="right-menu">
        <!-- <div
          v-for="(item, index) in navLinkList"
          :key="index"
          @click="toLink(item.path)"
        >
          {{ item.name }}
        </div> -->
      </div>
    </div>

    <div class="admin-box">
      <div class="admin">
        LeFt 超级管理员
        <div class="dropdown-box">
          <button @click="loginOut" class="le-text-btn-underline">
            退出登录
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      navLinkList: [
        {
          name: "系统管理",
          path: "/dummyDraft"
        },
        {
          name: "系统管理",
          path: "/dayActive"
        }
      ]
    };
  },
  watch: {},
  computed: {
    pathArr: function() {
      return this.$store.state.topBarPath;
    }
  },
  methods: {
    toPath(url) {
      this.$router.push({
        path: url
      });
    },
    loginOut() {
      localStorage.clear();

      this.$router.push({
        path: "/login"
      });
    }
  },
  created() {},
  mounted() {}
};
</script>
<style>
.el-breadcrumb__inner {
  cursor: pointer;
  font-weight: bold;
}
</style>
<style lang="scss" scoped>
.header {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  width: calc(100% - 180px);
  height: 40px;
  box-shadow: 0px 0px 100px #eeebeb;
  box-sizing: border-box;
  display: flex;
  white-space: nowrap;
  padding: 0px 50px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.header .right {
  display: flex;
  align-items: center;
}
.header .right-menu {
  display: flex;
}
.header .left-path {
  display: flex;
  z-index: 999999999;
}
.header .left-path div {
  display: flex;
  color: #333;
  align-items: center;
  cursor: pointer;
}
.header .left-path div:hover {
  color: #000;
}
.header .left-path div span {
  margin: 0px 5px;
}
.header .right-col {
  display: flex;
}
.header .right-menu > div {
  width: 100px;
  color: #303133;
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
}
.header .right-menu > div:hover {
  color: #000;
}
.header .right-col > div {
  width: 100px;
  color: #303133;
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
}
.header .right-col > div:hover {
  color: #000;
}
.admin {
  color: #000;
  font-weight: 700;
  cursor: pointer;
  margin-right: 30px;
}
.xgicon {
  padding: 0px 3px;
  display: inline-block;
  color: #909399;
}

.admin-box {
  position: absolute;
  z-index: 9999999;
  right: 60px;
  top: 10px;
}

.topStatusBar {
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topBarTips {
  margin-right: 10px;
  font-size: 16px;
}
.admin:hover .dropdown-box {
  opacity: 1;
  max-height: 180px;
}
.dropdown-box {
  width: 80px;
  max-height: 0px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all 0.25s;
  background: #fff;
  border: 2px #d1d1d1 solid;
  display: flex;
  padding: 10px;
  margin-left: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-dropdown-menu {
  padding: 10px 0px;
}

.dropdown-box button {
  margin-bottom: 15px;
}
.dropdown-box button:hover {
  font-weight: bold;
}
.dropdown-box button i {
  display: none;
}
.dropdown-box button:hover i {
  display: block;
}
.dropdown-box button:last-child {
  margin-bottom: 0px;
}
</style>