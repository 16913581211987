<template>
  <div class="wrapper nav">
    <div class="menuList">
      <div @click="toLink('/Home')" class="logo">
        <img :src="require('@/assets/images/logo.png')" alt="" />
      </div>
   
      <div @click="toUpArticle" class="post-btn">发表文章</div>

      <div
        class="parentsList"
        v-for="(parentsRoutes, index) in manageRoutes"
        :key="parentsRoutes + index"
      >
        <div
          @click="toLink(parentsRoutes.children[0].path)"
          class="parentsName"
          :class="{
            active:
              routerParentsName == parentsRoutes.name &&
              parentsRoutes.children.length == 1,
          }"
        >
          <span class="name"> {{ parentsRoutes.name }} </span>
          <span
            class="news"
            v-if="parentsRoutes.name == '消息箱'"
            v-show="unreadNum"
            >{{ unreadNum }}</span
          >
        </div>
        <div
          class="childrenList"
          v-show="parentsRoutes.children.length > 1 && manageSwitch"
          :class="{
            showActive: routerParentsName != parentsRoutes.name,
          }"
        >
          <div
            class="childName"
            v-for="(childrenList, index) in parentsRoutes.children"
            @click="toLink(childrenList.path)"
            :key="childrenList + index"
            :class="{
              active: routerChildName == childrenList.name,
            }"
          >
            <span class="name"> {{ childrenList.name }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      manageRoutes: [],
      manageSwitch: false,
      routerParentsName: "",
      unreadNum: 0,
  
    };
  },
  watch: {
    "$store.state.unreadNum": function (num) {
      this.unreadNum = num;
    },

    $route(toRouter) {
      this.routerParentsName = toRouter.meta.pathName[0];
    },
  },
  computed: {
    /**
     * 防止刷新之后 路由显示会异常
     * ------------------------------------------------------------------
     */
    routerChildName: function () {
      let childName = "";
      if (this.$route.meta[1] == "fromPath") {
        childName = this.$route.query.pathType;
      } else {
        childName = this.$route.meta.pathName[1];
      }

      return childName;
    },
  },
  methods: {
    toLink(path) {
      this.manageSwitch = true;
      this.$router.push({ path: path });
    },

    /**
     * 跳转发布页前判断 是否阅读了协议
     * 判断是否点击了不在显示
     * 从而选择是否要给用户跳转协议
     * ------------------------------------------------------------------
     */

    toUpArticle() {
      this.$router.push("/AddArticle");
    },
  },
  created() {
    this.routerParentsName = this.$route.meta.pathName[0];
    let _manageRoutes = [];
    for (let i = 0; i <= this.$router.options.routes.length - 1; i++) {
      if (this.$router.options.routes[i].name == "Main") {
        _manageRoutes = this.$router.options.routes[i].children;
      }
    }

    let manageP = [];
    let manageRoutes = [];
    for (let i = 0; i <= _manageRoutes.length - 1; i++) {
      if (!manageP.includes(_manageRoutes[i].meta.pathName[0])) {
        manageP[i] = _manageRoutes[i].meta.pathName[0];
        let data = {
          name: _manageRoutes[i].meta.pathName[0],
          children: [],
        };
        manageRoutes.push(data);
      }
    }
    for (let i = 0; i <= _manageRoutes.length - 1; i++) {
      if (manageP.length > 0 && _manageRoutes[i].meta.pathName.length <= 2) {
        for (let j = 0; j <= manageP.length - 1; j++) {
          if (manageP[j] == _manageRoutes[i].meta.pathName[0]) {
            let data2 = {
              name: _manageRoutes[i].meta.pathName[1],
              path: _manageRoutes[i].path,
            };
            manageRoutes[j].children.push(data2);
          }
        }
      }
    }
    this.$forceUpdate();
    this.manageRoutes = manageRoutes;
  },
  mounted() {
  
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: auto;
  color: #000;
  display: flex;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index:999;
  
}

.wrapper .logo {
  height: 150px;
  width: 100%;
}
.wrapper .logo img {
  cursor: pointer;
  width: 100px;
  margin: 14px 24px;
  height: auto;
}
.nav {
  background: #fff;
  transition: all 0.25s;
}
.nav .menuList {
  width: 180px;
  height: 100%;
  padding-bottom: 150px;
  overflow-y: scroll;
  box-sizing: border-box;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}

.nav .menuList::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.nav .menuList .parentsList .parentsName {
  font-weight: bold;
  padding-left: 24px;
  font-size: 18px;
  position: relative;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.nav .menuList .parentsList .parentsName .news {
  font-size: 13px;
  position: absolute;
  top: 10px;
  background: #fff;
  color: #000;
  padding: 0px 2px;
  height: 18px;
  line-height: 18px;
  margin-left: 5px;
}

.nav .menuList .parentsList .childName {
  height: 40px;
  transition: height 0.25s;
  line-height: 40px;
  padding-left: 63px;

  font-size: 16px;
  .name {
    cursor: pointer;
  }
}

.nav .menuList .active .name {
  display: inline-block;
  line-height: 22px;

  font-weight: 700;
  border-bottom: 3px #000 solid;
}
.nav .menuList .childName.active .name {
  display: inline-block;
  line-height: 17px;

  font-weight: 700;
  border-bottom: 3px #000 solid;
}

.showActive div {
  height: 0px !important;
  overflow: hidden;
}

.post-btn {
  width: calc(100% - 50px);
  height: 32px;
  margin: 80px 25px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
  background: #000;
  cursor: pointer;
}

</style>
 