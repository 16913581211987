import axios from "axios";
import store from "../store/index.js";
/****** 创建axios实例 ******/

function getAliyunBaseURL() {
  var domain = window.location.host;
  if (
    [
      "localhost:8080",
      "localhost:8081",
      "localhost:8082",
      "localhost:8083",
    ].indexOf(domain) >= 0
  ) {
    return "/aliyun";
  } else if (["adm.left793.com"].indexOf(domain) >= 0) {
    return "https://adm.oss.30sec.com.cn";
  } else {
    return "Error";
  }
}

/****** 创建axios实例 ******/
const service = axios.create({
  baseURL: getAliyunBaseURL(), // api的base_url
  // baseURL: '/api', // api的base_url
  timeout: 5000000, // 请求超时时间
});

//axios请求拦截
service.interceptors.response.use(
  (response) => {
    //成功请求到数据
    return Promise.resolve(response.data);
  },
  (error) => {
    //响应错误处理
    store.commit("SHOW_ERROR", true);
    console.log("error");
    console.log(error);
    console.log(JSON.stringify(error));
    return Promise.reject(error);
  }
);
//请求方法封装，拷贝直接用~
export default {
  POST(url, data) {
    return service({
      url: url,
      method: "post",
      data: data,
    });
  },
  GET(url, data) {
    return service({
      url: url,
      method: "get",
      params: data,
    });
  },
};
