

<template>
  <div class="wrapper flex-center-center">
    <tag-edit :id="$route.params.id"></tag-edit>
  </div>
</template>

<script>
import TagEdit from "@/components/custom/TagEdit.vue";
export default {
  components: { TagEdit },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    this.$store.commit("SET_TOPBARPATH", [
      { name: "首页管理", path: "/HomeTag" },
      { name: "更新词条", path: "/AddHomeTag" }
    ]);
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
</style> 