var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper nav"},[_c('div',{staticClass:"menuList"},[_c('div',{staticClass:"logo",on:{"click":function($event){return _vm.toLink('/Home')}}},[_c('img',{attrs:{"src":require('@/assets/images/logo.png'),"alt":""}})]),_c('div',{staticClass:"post-btn",on:{"click":_vm.toUpArticle}},[_vm._v("发表文章")]),_vm._l((_vm.manageRoutes),function(parentsRoutes,index){return _c('div',{key:parentsRoutes + index,staticClass:"parentsList"},[_c('div',{staticClass:"parentsName",class:{
          active:
            _vm.routerParentsName == parentsRoutes.name &&
            parentsRoutes.children.length == 1,
        },on:{"click":function($event){return _vm.toLink(parentsRoutes.children[0].path)}}},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(parentsRoutes.name)+" ")]),(parentsRoutes.name == '消息箱')?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.unreadNum),expression:"unreadNum"}],staticClass:"news"},[_vm._v(_vm._s(_vm.unreadNum))]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(parentsRoutes.children.length > 1 && _vm.manageSwitch),expression:"parentsRoutes.children.length > 1 && manageSwitch"}],staticClass:"childrenList",class:{
          showActive: _vm.routerParentsName != parentsRoutes.name,
        }},_vm._l((parentsRoutes.children),function(childrenList,index){return _c('div',{key:childrenList + index,staticClass:"childName",class:{
            active: _vm.routerChildName == childrenList.name,
          },on:{"click":function($event){return _vm.toLink(childrenList.path)}}},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(childrenList.name)+" ")])])}),0)])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }