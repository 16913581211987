<template>
  <div class="main-wrapper" :class="{ isDark: isDark }">
    <Sidebar class="LeFtNav"> </Sidebar>
    <div class="right">
      <Header v-if="isShowHeader" class="Header"> </Header>
      <div class="main" :class="{ isShowHeader: isShowHeader }">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Header from "../components/Header.vue";
export default {
  components: { Sidebar, Header },
  props: {},
  data() {
    return {
      isDark: false,
    };
  },
  watch: {},
  computed: {
    isShowHeader: function() {
      const hidHeaderPathName = ["Home"];
      if (hidHeaderPathName.indexOf(this.$route.name) >= 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.main-wrapper {
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.LeFtNav {
  width: 180px;
  position: fixed;
  transition: all 0.5s;
  height: 100%;
  bottom: 0px;
}
.right {
  width: calc(100% - 180px);
  min-width: 1050px;
  margin-left: 180px;
  height: auto;
  background: #f5f5f7;
  min-height: 100%;
}
.Header {
  z-index: 999;
  transition: all 0.5s;
}
.main {
  min-height: 100vh;
  max-width: 100%;
  transition: all 0.5s;
  margin: 0px;
}
.isShowHeader {
  padding-top: 70px;
}
</style>
