<template>
  <div id="drop-area" class="wrapper flex-center-center">
    <transition name="fade">
      <div v-show="uptips" class="up-tips">
        {{ uptips }}
      </div>
    </transition>

    <main>
      <div class="page_title">
        {{ title || "媒体库" }}
      </div>
      <div class="sreach-box">
        <el-input placeholder="输入搜索关键词" v-model="keyword"></el-input>
      </div>
      <el-button style="margin-bottom:20px" @click="getMediasList('new')"
        >刷新</el-button
      >
      <div
        @scroll="scrollEvent"
        id="mediaList"
        class="mediasList flex-center-start"
      >
        <div v-for="(item, index) in mediasList" :key="index" class="media">
          <label :for="'file' + index" class="label-btn">更换 </label>
          <div @click="openDialogSetTag(index, item)" class="edit-btn">
            编辑
          </div>
          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            @confirm="delMedia(item._id)"
            title="确定删除吗？"
          >
            <div slot="reference" class="del-btn">删除</div>
          </el-popconfirm>
          <div class="isSelect" v-show="isSelectIds.indexOf(item._id) >= 0">
            已选择
          </div>
          <input
            v-show="false"
            @change="updatedMediaFile(item._id, index)"
            type="file"
            :id="'file' + index"
          />
          <div
            @click="selectFn(item)"
            v-if="item.type == 'image'"
            class="imgbox"
          >
            <img
              :src="item.url + '?x-oss-process=style/left_thumbnail'"
              ref="mediaRef"
              alt=""
              srcset=""
            />
          </div>
          <div
            @click="selectFn(item)"
            v-if="item.type == 'video'"
            class="imgbox"
          >
            <video :src="item.url" controls></video>
          </div>

          <el-input
            class="info"
            @blur="updatedMedia(item._id, index)"
            :rows="2"
            placeholder="该图片未设置描述文本"
            type="textarea"
            v-model="item.info"
          ></el-input>
        </div>
        <div v-if="mediasList.length == 0" class="null">暂无结果</div>
      </div>
    </main>
    <el-dialog
      class="dialog-detail"
      :close-on-click-modal="false"
      :visible.sync="dialogSetTag"
      v-if="dialogSetTag"
      :modal="false"
      lock-scroll
      :append-to-body="true"
      width="1100px"
    >
      <svg
        @click="dialogSetTag = false"
        class="icon icon-dialog-close"
        style="left: calc(50% + 1100px / 2 - 35px)"
      >
        <use xlink:href="#lefticon-error"></use>
      </svg>
      <set-tag
        :img="setTagImg"
        @reloadData="(dialogSetTag = false), getMediasList('new')"
      ></set-tag>
    </el-dialog>
  </div>
</template>

<script>
import SetTag from "@/components/custom/SetTag.vue";

export default {
  components: { SetTag },
  props: {
    title: { default: "" },
    type: { default: "" },
    isSelectIds: {
      default: Array,
    },
  },
  data() {
    return {
      pageNum: 1,
      uptips: "",
      keyword: "",
      dialogSetTag: false,
      setTagImg: {},
      fileStatus: {
        success: 0,
        total: 0,
      },
      mediasList: [],
      form: {
        url: "",
        type: "",
        info: "",
      },
      isGet: false,
      timer: 0,
    };
  },
  watch: {
    keyword: function() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getMediasList("new");
      }, 500);
    },
  },
  computed: {},
  methods: {
    openDialogSetTag(index, img) {
      var media = this.$refs.mediaRef[index];
      this.$api
        .PUT("/adm/Media/" + img._id, {
          width: media.naturalWidth,
          height: media.naturalHeight,
        })
        .then(() => {
          this.dialogSetTag = true;
          img.width = media.naturalWidth;
          img.height = media.naturalHeight;

          this.setTagImg = img;
        });
    },

    delMedia(id) {
      this.$api.DELETE("/adm/Media/" + id).then((res) => {
        if (res.code == 200) {
          this.getMediasList("new");
        }
      });
    },
    updatedMedia(id, index) {
      this.$api
        .PUT("/adm/Media/" + id, {
          info: this.mediasList[index].info,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$notify({
              title: "更新成功",
              type: "success",
            });
          }
        });
    },

    updatedMediaFile(id, index) {
      var that = this;
      let file = document.getElementById("file" + index).files[0];
      document.getElementById("file" + index).value = null;
      that.$loading(true);
      return new Promise((resolve) => {
        var newfileName = that.$util.getFileName(file);
        var fileType = file.type.match(/(\S*)\//)[1];
        that.$oss
          .uploadFile(file, "public/Left/media/" + newfileName)
          .then((fileUrl) => {
            that.$api
              .PUT("/adm/Media/" + id, {
                url: fileUrl,
                fileName: newfileName,
                type: fileType,
              })
              .then((res) => {
                that.$loading(false);
                if (res.code == 200) {
                  that.getMediasList("new");
                  resolve();
                } else {
                  that.$notify({
                    type: "success",
                    title: "更新失败",
                  });
                  resolve();
                }
              });
          });
      });
    },
    selectFn(media) {
      if (this.title) {
        this.$emit("selectFn", media);
      }
    },

    /**
     * 拖拽视频上传
     * ------------------------------------------------------------------
     */

    async dropEvent(e) {
      var that = this;
      e.stopPropagation();
      e.preventDefault();
      this.uptips = false;

      let files = e.dataTransfer.files;
      this.fileStatus.total = files.length;
      var fileArr = Array.from(new Array(this.fileStatus.total).keys()).slice(
        -this.fileStatus.total
      );

      this.$loading(true);
      const promises = fileArr.map((item, index) => {
        return this.upMediaFn(files[index]);
      });
      await Promise.all(promises).then(function() {
        that.$loading(false);
        that.getMediasList("new");
        that.$notify({
          type: "success",
          title:
            "上传成功" +
            that.fileStatus.success +
            "条，失败" +
            (that.fileStatus.total - that.fileStatus.success) +
            "条",
        });
        that.fileStatus = {
          success: 0,
          total: 0,
        };
      });
    },

    upMediaFn(file) {
      var that = this;
      return new Promise((resolve) => {
        var newfileName = that.$util.getFileName(file);
        var fileType = file.type.match(/(\S*)\//)[1];
        that.$oss
          .uploadFile(file, "public/Left/media/" + newfileName)
          .then((fileUrl) => {
            that.$api
              .POST("/adm/Media", {
                url: fileUrl,
                fileName: newfileName,
                info: "",
                type: fileType,
              })
              .then((res) => {
                if (res.code == 200) {
                  that.fileStatus.success += 1;
                  resolve();
                } else {
                  resolve();
                }
              });
          });
      });
    },

    getMediasList(type) {
      if (type == "new") {
        this.pageNum = 1;
        this.mediasList = [];
      }
      this.isGet = false;
      this.$api 
        .GET("/adm/media/list", {
          keyword: this.keyword,
          type: this.type,
          pageNum: this.pageNum,
          pageSize: 50,
        })
        .then((res) => {
          if (res.code == 200) {
            var newMediasList = this.mediasList.concat(res.data);
            this.$set(this, "mediasList", newMediasList);
            if (res.data.length < 30) {
              this.isGet = false;
            } else {
              this.isGet = true;
            }
          }
        });
    },
    /**
     * 触底触发函数
     * ------------------------------------------------------------------
     */

    scrollEvent() {
      let list = document.getElementById("mediaList");
      if (list.scrollTop + list.clientHeight + 50 >= list.scrollHeight) {
        if (this.isGet) {
          this.pageNum += 1;
          this.getMediasList();
        }
      }
    },
  },
  created() {
    this.getMediasList("new");
    this.$store.commit("SET_TOPBARPATH", [
      { name: "通用设置", path: "/setImage" },
      { name: "图片", path: "/other" },
    ]);
  },
  mounted() {
    // 拖拽上传;
    var that = this;
    let dropArea = document.getElementById("drop-area");
    dropArea.addEventListener("drop", this.dropEvent, false);
    dropArea.addEventListener("dragleave", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });
    dropArea.addEventListener("dragenter", (e) => {
      that.isShowUptips = "释放鼠标上传媒体";
      e.stopPropagation();
      e.preventDefault();
    });
    dropArea.addEventListener("dragover", (e) => {
      that.isShowUptips = "释放鼠标上传媒体";
      e.stopPropagation();
      e.preventDefault();
    });
  },
};
</script>
<style>
.media .info .el-textarea__inner {
  border: none !important;
  background: #fff;
  font-size: 12px;
}
</style>
<style lang="scss" scoped>
main {
  width: calc(100% - 60px);
  min-height: 92vh;
  background: #fff;
  padding: 50px;
  margin-bottom: 50px;
  .sreach-box {
    margin-bottom: 30px;
  }
  .mediasList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    height: calc(100vh - 400px);
    overflow-y: scroll;
  }
  .media {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 220px;
    box-sizing: border-box;
    background: #f2f2f2;
    border: 2px #eee solid;
    box-sizing: border-box;
    opacity: 0.8;
    cursor: pointer;

    .imgbox {
      gap: 5px;
      height: 200px;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    video {
      width: 100%;
      height: 100%;
    }

    .info {
      border: none;
      outline: none;
      font-size: 12px;
    }
    .del-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      background: #000;
      color: #fff;
      opacity: 0;
      z-index: 9999;
      transition: all 0.25s;
      cursor: pointer;
    }
    .isSelect {
      position: absolute;
      left: 0px;
      top: 0px;
      background: #000;
      color: #fff;
      z-index: 999;
    }
    .label-btn {
      position: absolute;
      right: 70px;
      top: 0px;
      background: #000;
      color: #fff;
      opacity: 0;
      transition: all 0.25s;
      cursor: pointer;
    }
    .edit-btn {
      position: absolute;
      right: 35px;
      top: 0px;
      background: #000;
      color: #fff;
      opacity: 0;
      transition: all 0.25s;
      cursor: pointer;
    }
  }
  .media:hover {
    .del-btn {
      opacity: 1;
    }
    .label-btn {
      opacity: 1;
    }
    .edit-btn {
      opacity: 1;
    }
    opacity: 1;
  }
}
.null {
  width: 100%;
  height: 60vh;
  font-size: 30px;
  color: #999;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.up-tips {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.3);
  left: 0px;
  top: 0px;
  color: #fff;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  font-size: 40px;
}
</style>
