

<template>
  <div class="wrapper flex-center-center">
    <article-edit></article-edit>
  </div>
</template>

<script>
import ArticleEdit from "@/components/custom/ArticleEdit.vue";
export default {
  components: { ArticleEdit },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    // getHomeTagList() {
    //   this.$api.GET("/official/GetHomeTagList").then(res => {
    //     if (res.code == 200) {
    //       this.homeTagList = res.data;
    //     }
    //   });
    // }
  },
  created() {
    // this.getHomeTagList();
    this.$store.commit("SET_TOPBARPATH", [
      { name: "文章管理", path: "/Article" },
      { name: "添加文章", path: "/AddArticle" }
    ]);
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
</style> 