import * as type from './mutation-types'
export default {

    [type.SHOW_NOTIFY](state, data) {
        state.notify = data
    },
    [type.SET_LOADING](state, data) {
        state.loading = {
            status: data.status,
            mes: data.mes
        }
    },
    [type.SET_TOPBARPATH](state, data) {
        state.topBarPath = data
    },

}