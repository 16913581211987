<template>
  <div class="wrapper flex-center-center">
    <main>
      <div class="main-title">
        {{ article.title }}
      </div>
      <div class="content-wrapper">
        <div
          v-for="(article, index) in article.content"
          :key="index"
          class="content-box"
        >
          <div v-if="article.type == 101" class="content content-101">
            <div class="title">
              {{ article.title }}
            </div>
          </div>
          <div v-if="article.type == 102" class="content content-102">
            <div class="title">
              {{ article.title }}
            </div>
          </div>
          <div v-if="article.type == 103" class="content content-103">
            <div class="content">
              {{ article.content }}
            </div>
          </div>
          <div v-if="article.type == 104" class="content content-104">
            <div class="quote">
              {{ article.quote }}
            </div>
          </div>
          <div v-if="article.type == 105" class="content content-105">
            <img
              :src="
                article.main_pic.url + '?x-oss-process=style/left_thumbnail'
              "
              alt=""
              srcset=""
            />
            <div class="info">{{ article.main_pic.info }}</div>
          </div>
          <div v-if="article.type == 109" class="content content-109">
            <img
              :src="
                article.main_pic.url + '?x-oss-process=style/left_thumbnail'
              "
              alt=""
              srcset=""
            />
          </div>
          <div v-if="article.type == 106" class="content content-106">
            <div class="list-pic">
              <img
                v-for="(pic, index) in article.list_pic"
                :key="index"
                :src="pic.url + '?x-oss-process=style/left_thumbnail'"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div v-if="article.type == 107" class="content content-107">
            <div class="list-video">
              <video
                v-for="(video, index) in article.list_video"
                :key="index"
                :src="video.url"
                controls
              ></video>
            </div>
          </div>
          <div v-if="article.type == 108" class="content content-108">
            <div
              v-for="(pic, index) in article.list_pic"
              :key="index"
              class="img-box"
            >
              <img
                :src="pic.url + '?x-oss-process=style/left_thumbnail'"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      id: "",
      article: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    getArticle() {
      this.$loading(true);
      this.$api.GET("/adm/Article/" + this.id).then((res) => {
        this.$loading(false);
        if (res.code == 200) {
          this.article = res.data;
          this.$store.commit("SET_TOPBARPATH", [
            { name: "文章管理", path: "/Article" },
            { name: res.data.title, path: "/ArticleDetail/" + this.id },
          ]);
        }
      });
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getArticle();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
main {
  width: 1100px;
  min-height: 90vh;
  margin-bottom: 150px;
  height: auto;
  background: #fff;
  padding: 50px;
}

.main-title {
  font-size: 70px;
  color: #17bbf0;
  font-weight: 900;
  margin-bottom: 30px;
  white-space: pre;
  white-space: pre-line;
  word-wrap: break-word;
}
.content-wrapper {
  .content-box {
    white-space: pre;
    white-space: pre-line;
    word-wrap: break-word;
    .content {
    }
    .content-101 {
      .title {
        font-size: 50px;
        font-weight: 900;
        font-family: "Noto Serif SC";
        line-height: 75px;
        margin: 30px 0px;
      }
    }
    .content-102 {
      .title {
        font-size: 30px;
        font-weight: 900;
        line-height: 45px;
        margin: 10px 0px;
        font-family: "Noto Serif SC";
      }
    }
    .content-103 {
      .content {
        font-size: 30px;
        line-height: 45px;
        font-family: "Noto Serif SC";
        color: #000000;
        margin: 10px 0px;
      }
    }

    .content-104 {
      .quote {
        font-size: 30px;
        font-weight: 400;
        line-height: 45px;
        font-family: "Noto Serif SC";
        color: #919191;
        margin: 10px 0px;
      }
    }
    .content-105 {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      img {
        height: 400px;
        width: auto;
        margin-right: 60px;
      }
      .info {
        font-size: 20px;
        line-height: 27px;
      }
    }
    .content-109 {
      height: 400px;
      display: flex;

      img {
        height: 100%;
        width: auto;
      }
    }
    .content-106 {
      .list-pic::-webkit-scrollbar {
        width: 0 !important;
      }
      .list-pic::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      .list-pic {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: n;
        overflow-y: hidden;
        overflow-x: scroll;
        margin: 100px 0px;
        img {
          background: #17bbf0;
          height: 200px;
          box-sizing: border-box;
          width: auto;
          padding: 10px;
        }
        img:first-child {
          padding-left: 20px;
        }
      }
    }
    .content-107 {
      .list-video::-webkit-scrollbar {
        width: 0 !important;
      }
      .list-video::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      .list-video {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-y: hidden;
        overflow-x: scroll;
        margin: 100px 0px;

        video {
          background: #17bbf0;
          height: 200px;
          box-sizing: border-box;
          width: auto;
          padding: 10px;
        }
        video:first-child {
          padding-left: 20px;
        }
      }
    }
    .content-108 {
    }
  }
}
</style>
