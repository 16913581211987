import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'


/**
 * 设置路由规则
 * 
 * 在Main下面的子路由需要按照顺序
 * ------------------------------------------------------------------
 */
const routes = [{
        path: '/',
        component: resolve => require(['@/views/Index.vue'], resolve),
        meta: {
            pathName: ['', ''],
        },
    }, {
        path: '/',
        name: "Main",
        component: resolve => require(['@/views/Main.vue'], resolve),
        children: [{
                path: '/Home',
                name: "Home",
                component: resolve => require(['@/views/Home.vue'], resolve),
                meta: {
                    pathName: ['主页', '主页'],
                },
            }, {
                path: '/HomeTag',
                name: "HomeTag",
                component: resolve => require(['@/views/home/HomeTag.vue'], resolve),
                meta: {
                    pathName: ['首页管理', '词条管理'],
                },
            }, {
                path: '/Article',
                name: "Article",
                component: resolve => require(['@/views/article/Article.vue'], resolve),
                meta: {
                    pathName: ['文章管理', '文章列表'],
                },
            }, {
                path: '/Media',
                name: "Media",
                component: resolve => require(['@/views/media/Media.vue'], resolve),
                meta: {
                    pathName: ['媒体中心', '媒体库'],
                },
            }, {
                path: '/Common',
                name: "Common",
                component: resolve => require(['@/views/setting/Common.vue'], resolve),
                meta: {
                    pathName: ['通用设置', '常规'],
                },
            }, {
                path: '/AddHomeTag',
                name: "AddHomeTag",
                component: resolve => require(['@/views/home/AddHomeTag.vue'], resolve),
                meta: {
                    pathName: ['首页管理', '添加词条'],
                },
            }, {
                path: '/AddArticle',
                name: "AddArticle",
                component: resolve => require(['@/views/article/AddArticle.vue'], resolve),
                meta: {
                    pathName: ['文章管理', '添加文章'],
                },
            },
            {
                path: '/User',
                name: "User",
                component: resolve => require(['@/views/setting/User.vue'], resolve),
                meta: {
                    pathName: ['通用设置', '账号'],
                },
            },

        ]
    }, {
        path: '/',
        name: "MainOther",
        component: resolve => require(['@/views/Main.vue'], resolve),
        children: [{
            path: '/UpdatedArticle/:id',
            name: "UpdatedArticle",
            component: resolve => require(['@/views/article/UpdatedArticle.vue'], resolve),
            meta: {
                pathName: ['文章管理', '编辑文章'],
            },
        }, {
            path: '/UpdatedTag/:id',
            name: "UpdatedTag",
            component: resolve => require(['@/views/home/UpdatedTag.vue'], resolve),
            meta: {
                pathName: ['首页管理', '编辑词条'],
            },
        }, {
            path: '/ArticleDetail/:id',
            name: "ArticleDetail",
            component: resolve => require(['@/views/article/ArticleDetail.vue'], resolve),
            meta: {
                pathName: ['文章管理', '文章详情'],
            },
        }, ]
    },
    {
        path: '/Login',
        name: 'Login',
        component: resolve => require(['@/views/Login.vue'], resolve),
        meta: {
            title: "登录"
        },
    },
]

export default new Router({
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)