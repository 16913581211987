<template>
  <div
    id="app"
    v-loading="loading.status"
    :element-loading-text="loading.mes || '加载中'"
    class="dark-mode-box"
  >
    <svg
      v-if="!goPCtips"
      v-show="loading.status"
      @click="closeLoading"
      class="icon icon-Loading-close"
    >
      <use xlink:href="#lefticon-error"></use>
    </svg>
    <!-- <div class="dialog goPCtips" v-if="goPCtips">
      <svg @click="goPCtips = false" class="icon icon-dialog-close">
        <use xlink:href="#lefticon-error"></use></svg
      >请前往电脑端操作
    </div> -->
    <router-view />
  </div>
</template>
<script>
// import DevicePixelRatio from "@/assets/js/devicePixelRatio.js";

export default {
  props: {},
  data() {
    return {
      goPCtips: false,
      loading: {
        status: false,
        mes: "",
      },
    };
  },
  watch: {
    "$store.state.notify": function (notify) {
      this.$notify({
        type: notify.type,
        title: notify.title,
      });
    },
    "$store.state.loading": function (loading) {
      this.loading = loading;
    },
  },
  computed: {},
  methods: {
    closeLoading() {
      this.$loading(false);
    },
  },
  created() {
    // new DevicePixelRatio().init();
  },
  mounted() {
    var that = this;
    var w = document.body.clientWidth;
    if (w < 1024) {
      that.goPCtips = true;
    } else {
      that.goPCtips = false;
    }
    window.onresize = () => {
      return (() => {
        w = document.body.clientWidth;
        if (w < 1024) {
          that.goPCtips = true;
        } else {
          that.goPCtips = false;
        }
      })();
    };
  },
};
</script>
<style lang="scss" scoped>
#app {
  height: 100%;
  min-height: 100%;
}
.icon-Loading-close {
  color: #000;
  cursor: pointer;
  position: fixed;
  right: 30px;
  z-index: 9999999999;
  top: 30px;
  font-size: 20px;
}

.goPCtips {
  position: fixed;
  z-index: 9999999999;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  height: 100vh;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goPCtips .icon-dialog-close {
  color: #fff;
}


</style>

