import axios from "axios";
import store from "../store/index.js";
import router from "../router";

function getApiBaseURL() {
  var domain = window.location.host;
  var href = window.location.href;
  if (href.indexOf("/en#/") >= 0) {
    return "https://api.left793.com/en/";
  } else if (href.indexOf("/v2#/") >= 0) {
    // return "https://api.left793.com/v2/";  
    return "https://api.left793.com/v2/"; 

  } else if (["adm.left793.com"].indexOf(domain) >= 0) {
    return "https://api.left793.com";
  } else if (
    [ 
      "localhost:8080",
      "localhost:8081",
      "localhost:8082",
      "localhost:8083",
    ].indexOf(domain) >= 0 &&
    href.indexOf("/en#/") >= 0
  ) {
    return "/apien";
  } else if (
    [
      "localhost:8080",
      "localhost:8081",
      "localhost:8082",
      "localhost:8083",
    ].indexOf(domain) >= 0
  ) {
    return "/api";
  } else {
    return "Error";
  }
}

/****** 创建axios实例 ******/
const service = axios.create({
  baseURL: getApiBaseURL(), // api的base_url
  // baseURL: '/api', // api的base_url
  timeout: 5000000, // 请求超时时间
});

service.interceptors.request.use((config) => {
  // 修改请求头信息
  try {
    if (localStorage.getItem("token")) {
      config.headers["Authorization"] = JSON.parse(
        localStorage.getItem("token")
      );
    }
  } catch {
    localStorage.removeItem("token");
  }

  return config;
});
//axios请求拦截
service.interceptors.response.use(
  (res) => {
    //成功请求到数据
    if (res.data.code == 200) {
      return res.data;
    } else if (res.data.code == 555) {
      localStorage.clear();
      router.push({ path: "/login" });
    } else {
      if (localStorage.getItem("token")) {
        store.commit("SHOW_NOTIFY", {
          type: "error",
          title: res.data.tips,
        });
      }
      return res;
    }
  },
  (error) => {
    //响应错误处理
    store.commit("SHOW_ERROR", true);
    console.log("error");
    console.log(error);
    console.log(JSON.stringify(error));
    return Promise.reject(error);
  }
);

//请求方法封装，拷贝直接用~
export default {
  GET(url, data = {}) {
    return service({
      url: url,
      method: "get",
      params: data,
      data: "",
    });
  },

  POST(url, data = {}) {
    return service({
      url: url,
      method: "post",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  PUT(url, data = {}) {
    return service({
      url: url,
      method: "put",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  DELETE(url, data = {}) {
    return service({
      url: url,
      method: "delete",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
};
