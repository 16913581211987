<template>
  <div class="wrapper flex-center-center">
    <main>
      <div class="page_title">文章列表</div>
      <div class="sreach-box">
        <el-input placeholder="输入搜索关键词" v-model="keyword"></el-input>
      </div>
      <div v-for="(item, index) in articleList" :key="'articleList' + index">
        <div class="article">
          <div @click="selectArticle(item._id)" class="main_title">
            {{ item.title }}
          </div>
          <div class="content-box">
            <div
              v-for="(content, index) in item.content"
              :key="'content' + index"
            >
              <div v-if="content.type == 101">
                <div class="small_title">
                  {{ content.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="footer flex-center-sb">
            <div class="update_time">最后更新时间： {{ item.update_time }}</div>
            <div v-if="type == 'list'" class="flex-center-start">
              <div @click.stop="toUpdated(item._id)" class="updated-btn">
                编辑文章
              </div>
              <el-popconfirm
                confirm-button-text="好的"
                cancel-button-text="不用了"
                icon="el-icon-info"
                icon-color="red"
                @confirm="delArticle(item._id)"
                title="确定删除吗？"
              >
                <div slot="reference" class="del-btn">删除文章</div>
              </el-popconfirm>
            </div>
          </div>
        </div>
      </div>
      <div v-if="articleList.length == 0" class="null">
        {{ loading ? "加载中···" : "暂无结果" }}
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    type: {
      default: "",
    },
  },
  data() {
    return {
      articleList: [],
      keyword: "",
      timer: "",
      loading: false,
    };
  },
  watch: {
    keyword: function () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getArticleList();
      }, 500);
    },
  },
  computed: {},
  methods: {
    getArticleList() {
      this.loading = true;
      this.$api
        .GET("/adm/article/list", {
          keyword: this.keyword,
        })
        .then((res) => {
          if (res.code == 200) {
            this.articleList = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    delArticle(id) {
      this.$api.DELETE("/adm/article/" + id).then((res) => {
        if (res.code == 200) {
          this.getArticleList();
        }
      });
    },
    toUpdated(id) {
      this.$router.push("/UpdatedArticle/" + id);
    },
    selectArticle(id) {
      if (this.type == "list") {
        this.$router.push("/ArticleDetail/" + id);
      } else if (this.type == "select") {
        this.$emit("selectFn", id);
      }
    },
  },
  created() {
    this.getArticleList();
    this.$store.commit("SET_TOPBARPATH", [
      { name: "文章管理", path: "/Article" },
      { name: "文章列表", path: "/Article" },
    ]);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
main {
  width: 1000px;
  min-height: 90vh;
  margin-bottom: 150px;
  height: auto;
  background: #fff;
  padding: 50px;
}

.sreach-box {
  margin-bottom: 30px;
}

.article {
  padding: 20px 0px;
  border-bottom: 1px #f5f5f7 solid;
  .main_title {
    cursor: pointer;
  }
  .content-box {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .update_time {
    color: #a0a0a0;
    font-size: 13px;
  }
  .del-btn {
    background: #000;
    color: #fff;
    cursor: pointer;
    margin: 0px 10px;
  }
  .updated-btn {
    background: #000;
    color: #fff;
    margin-left: 20px;
    cursor: pointer;
  }
}
.null {
  width: 100%;
  height: 200px;
  font-size: 20px;
  color: #999;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
