<template>
  <div class="wrapper flex-center-center">
    <main>
      <div class="page_title">词条列表</div>
      <div class="sreach-box">
        <el-input placeholder="输入搜索关键词" v-model="keyword"></el-input>
      </div>
      <div
        v-for="(item, index) in homeTagList"
        :key="index"
        class="tag-box flex-center-start"
      >
        <div
          v-if="item.article"
          @click="toArticle(item.articleId, item.link)"
          class="article-title"
        >
          绑定文章： {{ item.article.title }}
        </div>
        <div
          v-if="!item.article && item.link"
          class="article-title"
          @click="toArticle(item.articleId, item.link)"
        >
          跳转链接：<a :href="item.link" target="_blank"> {{ item.link }}</a>
        </div>
        <div class="orderNum-box flex-col-center-center">
          <div class="title">排序号</div>
          <div class="orderNum">
            <input
              type="text"
              @blur="updatedOrderNum(item._id, item.orderNum)"
              v-model="item.orderNum"
            />
          </div>
        </div>
        <div @click="selectArticle(item._id)" class="select-article-btn">
          绑定文章
        </div>
        <div @click="toUpdated(item._id)" class="updated-btn">编辑词条</div>
        <el-popconfirm
          confirm-button-text="好的"
          cancel-button-text="不用了"
          icon="el-icon-info"
          icon-color="red"
          @confirm="delTag(item._id)"
          title="确定删除吗？"
        >
          <div slot="reference" class="del-btn">删除词条</div>
        </el-popconfirm>

        <div class="tag tag-101" v-if="item.type == 101">
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="tag tag-102" v-if="item.type == 102">
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="tag tag-103" v-if="item.type == 103">
          <div class="title">{{ item.title }}</div>
          <div class="info">{{ item.info }}</div>
        </div>
        <div class="tag tag-104" v-if="item.type == 104">
          <div class="main_pic">
            <img :src="item.main_pic.url" alt="" srcset="" />
          </div>
        </div>
        <div class="tag tag-105" v-if="item.type == 105">
          <img
            :src="item.bg_picId != '' ? item.bg_pic.url : ''"
            alt=""
            srcset=""
            class="bg_pic"
          />
          <div class="list_pic">
            <img
              v-for="(list_pic, index) in item.list_pic"
              :key="index"
              :src="list_pic.url"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="tag tag-106" v-if="item.type == 106">
          <img
            :src="item.bg_picId != '' ? item.bg_pic.url : ''"
            alt=""
            srcset=""
            class="bg_pic"
          />
          <img
            :src="item.main_picId != '' ? item.main_pic.url : ''"
            alt=""
            srcset=""
            class="main_pic"
          />
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="tag tag-107" v-if="item.type == 107">
          <div class="title">{{ item.title }}</div>
          <div class="list_pic">
            <img
              v-for="(list_pic, index) in item.list_pic"
              :key="index"
              :src="list_pic.url"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="tag tag-108" v-if="item.type == 108">
          <div class="main_pic">
            <img :src="item.main_pic.url" alt="" srcset="" />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="info">{{ item.info }}</div>
        </div>
      </div>
      <el-dialog
        class="dialog-detail"
        :close-on-click-modal="false"
        :modal="false"
        :append-to-body="true"
        :visible.sync="dialogSetArticle"
        width="800px"
      >
        <svg
          @click="dialogSetArticle = false"
          class="icon icon-dialog-close"
          style="left: calc(50% + 800px / 2 - 35px)"
        >
          <use xlink:href="#lefticon-error"></use>
        </svg>
        <article-list @selectFn="setArticle" :type="'select'"></article-list>
      </el-dialog>
    </main>
  </div>
</template>

<script>
import ArticleList from "@/components/custom/ArticleList.vue";

export default {
  components: { ArticleList },
  props: {},
  data() {
    return {
      homeTagList: [],
      keyword: "",
      curTagId: "", // 当前选择的ID
      dialogSetArticle: false,
      timer: "",
    };
  },
  watch: {
    keyword: function () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getHomeTagList();
      }, 500);
    },
  },
  computed: {},
  methods: {
    getHomeTagList() {
      this.$api
        .GET("/adm/homeTag/list", {
          keyword: this.keyword,
        })
        .then((res) => {
          if (res.code == 200) {
            this.homeTagList = res.data;
            this.$nextTick(function () {
              this.$forceUpdate();
            });
          }
        });
    },
    toArticle(id, link) {
      if (id) {
        window.open("https://left793.30sec.com.cn/article?id=" + id, "_blank");
      } else if (link) {
        window.open(link, "_blank");
      }
    },
    delTag(id) {
      this.$api.DELETE("/adm/HomeTag/" + id).then((res) => {
        if (res.code == 200) {
          this.getHomeTagList();
        }
      });
    },
    toUpdated(id) {
      this.$router.push("/UpdatedTag/" + id);
    },
    selectArticle(id) {
      this.dialogSetArticle = true;
      this.curTagId = id;
    },
    setArticle(articleId) {
      this.$api
        .PUT("/adm/HomeTag/" + this.curTagId, {
          articleId: articleId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$notify({
              type: "success",
              title: "绑定成功",
            });
            this.getHomeTagList();
          }
          this.dialogSetArticle = false;
          this.curTagId == "";
        });
    },
    updatedOrderNum(id, orderNum) {
      if (orderNum == 0) {
        this.$notify({
          type: "error",
          title: "排序号不能为0",
        });
        this.getHomeTagList();
        return;
      }
      this.$api
        .PUT("/adm/HomeTag/" + id, {
          orderNum: orderNum,
        })
        .then((res) => {
          if (res.code == 200) {
            this.getHomeTagList();
          }
        });
    },
  },
  created() {
    this.getHomeTagList();
    this.$store.commit("SET_TOPBARPATH", [
      { name: "首页管理", path: "/HomeTag" },
      { name: "词条列表", path: "/HomeTag" },
    ]);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
main {
  width: 1200px;
  min-height: 90vh;
  margin-bottom: 150px;
  height: auto;
  background: #fff;
  padding: 50px;
}

.sreach-box {
  margin-bottom: 30px;
}
.tag-box {
  position: relative;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px #eee solid;

  .article-title {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #999;
    font-size: 10px;
    font-weight: 400;
    background: #eee;
    max-width: 300px;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
    cursor: pointer;
  }
  .article-link {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #999;
    font-size: 10px;
    font-weight: 400;
    background: #eee;
    max-width: 300px;
    cursor: pointer;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
  }
  .del-btn {
    position: absolute;
    right: 0px;
    top: 85px;
    background: #000;
    color: #fff;
    z-index: 99;
    cursor: pointer;
  }
  .updated-btn {
    position: absolute;
    right: 0px;
    top: 55px;
    background: #000;
    z-index: 99;
    color: #fff;
    cursor: pointer;
  }
  .select-article-btn {
    position: absolute;
    right: 0px;
    top: 25px;
    background: #000;
    z-index: 99;
    color: #fff;
    cursor: pointer;
  }
  .tag {
    position: relative;
    max-width: 900px;
    margin: 20px 0px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-y: hidden;
  }
  .tag::-webkit-scrollbar {
    width: 0 !important;
  }
  .tag::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }
  .orderNum-box {
    margin-top: 40px;
    margin-right: 30px;
    padding: 5px;
    background: #000;
    color: #fff;
    .title {
      font-size: 13px;
      font-weight: bold;
    }
    .orderNum {
      input {
        background: none;
        width: 70px;
        color: #fff;
        text-align: center;
        border: none;
        font-size: 20px;
      }
    }
  }

  .tag-101 {
    .title {
      font-size: 60px;
      font-weight: bold;
      color: #000;
      display: inline;
      font-weight: 800;
    }
  }
  .tag-102 {
    .title {
      font-size: 60px;
      font-weight: bold;
      color: #17bbf0;
      display: inline;
      font-weight: 800;
    }
  }
  .tag-103 {
    .title {
      font-size: 60px;
      font-weight: bold;
      color: #000;
      display: inline;
      font-weight: 800;
    }
    .info {
      width: 220px;
      font-size: 12px;
      margin-left: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
    }
  }
  .tag-104 {
    padding: 0px 30px;
    .main_pic {
      height: 100px;
      img {
        height: 100%;
      }
    }
  }
  .tag-105 {
    .bg_pic {
      height: 100px;
      width: auto;
    }
    .list_pic {
      img {
        height: 80px;
        margin: 0px 2px;
        width: auto;
      }
    }
  }
  .tag-106 {
    .bg_pic {
      height: 100px;
      width: auto;
      display: block;
    }
    .main_pic {
      height: 100px;
      width: auto;
      display: none;
    }

    .title {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 60px;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      color: #fff;
      font-weight: 800;
    }
  }
  .tag-106:hover {
    .bg_pic {
      display: none;
    }
    .main_pic {
      display: block;
    }
  }
  .tag-107 {
    .title {
      font-size: 60px;
      font-weight: bold;
      color: #000;
      display: inline;
      font-weight: 800;
      margin-right: 10px;
    }
    .list_pic {
      img {
        height: 80px;
        margin: 0px 2px;
        width: auto;
      }
    }
  }
  .tag-108 {
    .title {
      font-size: 60px;
      font-weight: bold;
      color: #000;
      display: inline;
      font-weight: 800;
      margin-left: 10px;
    }
    .main_pic {
      img {
        height: 80px;
      }
    }
    .info {
      width: 220px;
      font-size: 12px;
      margin-left: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
    }
  }
}
</style>
