// 是否是邮箱
function isEmail(s) {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}
// 是否是手机
function isMobile(s) {
    return /^1[0-9]{10}$/.test(s)
}
// 是否是URL
function isURL(s) {
    return /^http[s]?:\/\/.*/.test(s)
}
// 是否是字符串
function isString(o) {
    return Object.prototype.toString.call(o).slice(8, -1) === 'String'
}
// 是否是数字
function isNumber(o) {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Number'
}

function isBoolean(o) {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Boolean'
}
// 是否为null
function isNull(o) {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Null'
}
// 是否是数组
function isArray(o) {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Array'
}
// 滚动到顶部
function scrollToTop() {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
}

// 获取URL 文件名
function getFileNameByURL(url) {
    return url.substring(url.lastIndexOf('/') + 1);
}


/**
 * 两两对象比较 得出修改的属性和值
 * @param {} {}
 */

function checkUpdataFormByObject(checkForm, originalForm) {
    let form = "";
    if (checkForm instanceof Object && originalForm instanceof Object) {
        //取对象a和b的属性名
        form = {}
        var adProps = Object.getOwnPropertyNames(checkForm);
        //循环取出属性名，再判断属性值是否一致
        for (var i = 0; i < adProps.length; i++) {
            var propName = adProps[i];
            if (checkForm[propName] != originalForm[propName]) {
                form[propName] = checkForm[propName]
            }

        }
        delete form.__ob__
        return form;
    }
}

/**
 * 两两数组比较 得出修改的数组 无修改则返回空数组
 * @param [] []
 */
function checkUpdataFormByArray(checkForm, originalForm) {
    let form = [];
    if (checkForm instanceof Array && originalForm instanceof Array) {
        if (JSON.stringify(checkForm) != JSON.stringify(originalForm)) {
            form = checkForm
        }
        return form
    }
}


/**
 * 得到文件的扩展名
 * @param {} filename
 */
function getFileExt(filename) {
    var d = /.[^.]+$/.exec(filename);
    var ext = new String(d);
    var s = ext.toLowerCase();
    return s;
}


/**
 * 得到文件上传名
 * @param {} file
 */
function getFileName(file) {
    return getNowTimeNum() + "_" + file.name
}


/**
 * 得到纯数字时间
 * @param {} 
 */
function getNowTimeNum() {
    var nowTime = new Date();
    var year = nowTime.getFullYear();
    var month = nowTime.getMonth() + 1;
    var day = nowTime.getDate();
    var hour = nowTime.getHours();
    var minutes = nowTime.getMinutes();
    var seconds = nowTime.getSeconds();
    if (hour <= 9) { hour = "0" + hour }
    if (minutes <= 9) { minutes = "0" + minutes }
    if (seconds <= 9) { seconds = "0" + seconds }
    return year.toString() + month.toString() + day.toString() + hour.toString() + minutes.toString() + seconds.toString()
}


/**
 * 获取字符长度
 * @param val
 */

function getTextLength(val) {
    let len = 0;
    for (let i = 0; i < val.length; i++) {
        let length = val.charCodeAt(i);
        if (length >= 0 && length <= 128) {
            len += 0.5;
        } else {
            len += 1;
        }
    }
    return Math.trunc(len);

}





export default {
    isEmail,
    isMobile,
    isURL,
    isString,
    isNumber,
    isBoolean,
    isNull,
    isArray,
    scrollToTop,
    getFileNameByURL,
    checkUpdataFormByArray,
    checkUpdataFormByObject,
    getNowTimeNum,
    getFileExt,
    getTextLength,
    getFileName

}