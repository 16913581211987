import aliyun from "./aliyun";
import axios from "axios";

function getHttpOSSBaseURL() {
  var domain = window.location.host;
  if (
    [
      "localhost:8080",
      "localhost:8081",
      "localhost:8082",
      "localhost:8083",
    ].indexOf(domain) >= 0
  ) {
    return "/httpOSS";
  } else if (["adm.left793.com"].indexOf(domain) >= 0) {
    return "https://30sec.oss-cn-guangzhou.aliyuncs.com";
  } else {
    return "Error";
  }
}

/****** 创建axios实例 ******/
const httpOSS = axios.create({
  baseURL: getHttpOSSBaseURL(), // api的base_url
  timeout: 5000000, // 请求超时时间
});

function uploadFile(file, filePathName) {
  return new Promise(function(resolve) {
    aliyun
      .GET("/oss/Sign", {
        // 储存路径+文件名
        pathName: filePathName,
      })
      .then((res) => {
        let ossInfo = res.data;
        let key = ossInfo.pathName;
        let param = new FormData(); // 创建form对象
        param.append("name", file.name); // 通过append向form对象添加数据
        param.append("key", key);
        param.append("policy", ossInfo.policy);
        param.append("OSSAccessKeyId", ossInfo.OSSAccessKeyId);
        param.append("success_action_status", 200);
        param.append("signature", ossInfo.signature);
        param.append("file", file, file.name); // 通过append向form对象添加数据

        // 添加请求头
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };

        // 上传到OSS
        httpOSS.post("/", param, config).then(() => {
          // 返回图路径
          let fileURL = ossInfo.host + "/" + filePathName;
          resolve(fileURL);
        });
      });
  });
}
export default {
  uploadFile,
};
