<template>
  <article-list :type="'list'"></article-list>
</template>

<script>
import ArticleList from "@/components/custom/ArticleList.vue";

export default {
  components: { ArticleList },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
</style>