<template>
  <div class="wrapper"></div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    this.$router.push("/Home");
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
</style>