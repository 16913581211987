<template>
  <div class="wrapper">
    <div class="set-box">
      <img @click="setTopLeft" :src="img.url" alt="" srcset="" />
      <div
        class="tag"
        v-drag="i"
        v-for="(tag, i) in tagsList"
        :key="`tag${i}`"
        :style="`left:${tag.offsetX * 100}% ;top:${tag.offsetY * 100}%`"
      >
        <!-- `transform: translate(${tag.offsetX * 1000}%,${tag.offsetY * 1000}%);` -->
        <img
          v-if="tag.type == 101"
          :src="require('@/assets/images/icon_tag101.svg')"
        />
        <img
          v-if="tag.type == 102"
          :src="require('@/assets/images/icon_tag102.svg')"
        />
        <img
          v-if="tag.type == 103"
          :src="require('@/assets/images/icon_tag103.svg')"
        />
        <img
          v-if="tag.type == 104"
          :src="require('@/assets/images/icon_tag104.svg')"
        />

        {{ tag.title }}
      </div>
    </div>
    <div class="set-tag">
      <div class="title">标签列表</div>
      <div v-for="(tag, i) in tagsList" :key="`t${i}`" class="tag-form">
        <div class="del-btn" @click="tagsList.splice(i, 1)">
          删除
        </div>
        <div style="width:55%">
          标题 <el-input type="text" v-model="tag.title" />
        </div>
        <div style="width:40%;margin-left:20px">
          类型
          <el-select type="text" v-model="tag.type">
            <el-option
              v-for="item in tagTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="width:100%;margin-top:20px">
          链接 <el-input type="text" v-model="tag.url" />
        </div>
      </div>
      <div style="margin-top:20px" v-if="tagsList.length == 0">暂无标签</div>
      <el-button style="margin-top:20px" @click="saveFn">立即保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    img: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tagsList: [],
      tagTypeOptions: [
        {
          value: 101,
          label: "时间",
        },
        {
          value: 102,
          label: "地址",
        },
        {
          value: 103,
          label: "购物",
        },
        {
          value: 104,
          label: "信息",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    setTopLeft(e) {
      this.tagsList.push({
        info: "",
        offsetX: (e.offsetX / e.target.offsetWidth).toFixed(3),
        title: "未命名" + this.tagsList.length,
        url: "",
        offsetY: (e.offsetY / e.target.offsetHeight).toFixed(3),
        type: 103,
      });
    },

    async saveFn() {
      let tagsIds = await Promise.all(
        this.tagsList.map((e) => {
          if (e._id) {
            return this.updateTag(e);
          } else {
            return this.addTag(e);
          }
        })
      );
      this.$api
        .PUT("/adm/media/" + this.img._id, {
          tagsIds: tagsIds,
        })
        .then((res) => {
          if (res.code != 200) {
            this.$notify({
              title: `保存失败`,
              type: "success",
            });
          } else {
            this.$notify({
              title: `保存成功`,
              type: "success",
            });
            this.$emit("reloadData");
          }
        });
    },
    addTag(e) {
      return new Promise((resolve) => {
        this.$api.POST("/adm/tag", e).then((res) => {
          if (res.code != 200) {
            this.$notify({
              title: `添加失败`,
              type: "error",
            });
            resolve(null);
          } else {
            resolve(res.data._id);
          }
        });
      });
    },
    updateTag(e) {
      return new Promise((resolve) => {
        this.$api.PUT("/adm/tag/" + e._id, e).then((res) => {
          if (res.code != 200) {
            this.$notify({
              title: `${e._id}更新失败`,
              type: "error",
            });
            resolve(null);
          } else {
            resolve(res.data._id);
          }
        });
      });
    },
  },
  created() {
    this.tagsList = this.img.tags;
  },
  mounted() {},
  directives: {
    drag(el, v, vnode) {
      let that = vnode.context;

      var width = 600;
      var height = (600 / that.img.width) * that.img.height;
      el.onmousedown = function(e) {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = function(e) {
          if (e.pageX - disx > width) {
            that.tagsList[v.value].offsetX = 0.95;
          } else if (e.pageX - disx < 0) {
            that.tagsList[v.value].offsetX = 0;
          } else {
            that.tagsList[v.value].offsetX = ((e.pageX - disx) / width).toFixed(
              3
            );
          }
          if (e.pageY - disy > height) {
            that.tagsList[v.value].offsetY = 0.95;
          } else if (e.pageY - disy < 0) {
            that.tagsList[v.value].offsetY = 0;
          } else {
            that.tagsList[v.value].offsetY = (
              (e.pageY - disy) /
              height
            ).toFixed(3);
          }
        };
        document.onmouseup = function() {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
  },
};
</script>
<style>
.el-input__inner {
  background: none !important;
}
</style>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .tag {
    background: #000;
    color: #fff;
    margin-right: 10px;
    white-space: nowrap;
  }
  .set-box {
    position: relative;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    img {
      width: 600px;
      height: auto;
      cursor: pointer;
      -webkit-user-select: none;
      -webkit-user-drag: none;
    }
    .tag {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 9;
      cursor: move;
      font-weight: 400;
      font-size: 14px;
      padding: 0px 4px;
      transform: rotate(-9deg);
      white-space: nowrap;
      img {
        height: 12px;
        width: auto;
        margin-right: 5px;
      }
    }
  }
  .set-tag {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% - 550px);
    margin-left: 30px;
    .tag-form {
      position: relative;
      margin: 10px 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      background: #eee;
      overflow: hidden;
      .del-btn {
        position: absolute;
        right: 0px;
        top: 0px;
        display: none;
        background: #000;
        color: #fff;
        transition: all 0.25s;
        cursor: pointer;
      }
    }
    .tag-form:hover {
      .del-btn {
        display: block;
      }
    }
    .title {
      font-size: 16px;
    }
  }
}
</style>
