<template>
  <div class="wrapper">
    <main class="form flex-start-sb">
      <div class="le-form-item">
        <div class="page_title">添加词条</div>
        <div class="le-form-title">词条类型</div>
        <div class="le-form-main">
          <div>
            <el-radio v-model="form.type" :label="101">
              <img
                class="homeTag-icon"
                style="height: 28.5px"
                :src="require('@/assets/images/homeTag-icon-1.svg')"
                alt=""
                srcset=""
              />
            </el-radio>

            <el-radio v-model="form.type" :label="102">
              <img
                class="homeTag-icon"
                style="height: 28.5px"
                :src="require('@/assets/images/homeTag-icon-2.svg')"
                alt=""
                srcset=""
              />
            </el-radio>
            <el-radio v-model="form.type" :label="103">
              <img
                class="homeTag-icon"
                :src="require('@/assets/images/homeTag-icon-3.svg')"
                alt=""
                srcset=""
              />
            </el-radio>
            <el-radio v-model="form.type" :label="104">
              <img
                class="homeTag-icon"
                :src="require('@/assets/images/homeTag-icon-4.svg')"
                alt=""
                srcset=""
              />
            </el-radio>
            <el-radio v-model="form.type" :label="105">
              <img
                class="homeTag-icon"
                :src="require('@/assets/images/homeTag-icon-5.svg')"
                alt=""
                srcset=""
              />
            </el-radio>
            <el-radio v-model="form.type" :label="106">
              <img
                class="homeTag-icon"
                :src="require('@/assets/images/homeTag-icon-6.svg')"
                alt=""
                srcset=""
              />
            </el-radio>
            <el-radio v-model="form.type" :label="107">
              <img
                class="homeTag-icon"
                :src="require('@/assets/images/homeTag-icon-7.svg')"
                alt=""
                srcset=""
              /> </el-radio
            ><el-radio v-model="form.type" :label="108">
              <img
                class="homeTag-icon"
                :src="require('@/assets/images/homeTag-icon-8.svg')"
                alt=""
                srcset=""
              />
            </el-radio>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div
          class="le-form-item"
        >
          <div class="le-form-title">词条标题</div>
          <div class="le-form-main">
            <div class="input-check-box">
              <el-input v-model="form.title"></el-input>
            </div>
          </div>
        </div>
        <div class="le-form-item">
          <div class="le-form-title">
            绑定文章id（优先）
            <span
              v-if="!form.articleId"
              @click.stop="dialogSetArticle = true"
              class="le-text-btn-underline"
            >
              绑定</span
            >
            <span
              v-if="form.articleId"
              @click.stop="clearArticleId()"
              class="le-text-btn-underline"
            >
              取消绑定</span
            >
          </div>
          <div class="le-form-main">
            <div class="input-check-box">
              <el-input disabled v-model="form.articleId"></el-input>
            </div>
          </div>
        </div>
        <div class="le-form-item">
          <div class="le-form-title">跳转链接</div>
          <div class="le-form-main">
            <div class="input-check-box">
              <el-input v-model="form.link"></el-input>
            </div>
          </div>
        </div>
        <div v-show="[103, 108].indexOf(form.type) >= 0" class="le-form-item">
          <div class="le-form-title">词条描述</div>
          <div class="le-form-main">
            <div class="input-check-box">
              <el-input
                :rows="4"
                type="textarea"
                v-model="form.info"
              ></el-input>
            </div>
          </div>
        </div>
        <div v-show="[105, 106].indexOf(form.type) >= 0" class="le-form-item">
          <div class="le-form-title">词条背景</div>
          <div class="le-form-main">
            <img
              v-if="form.bg_pic.url"
              @click="
                openSelectMedia({ type: 'bg_pic', title: '选择词条背景' })
              "
              style="width: 400px"
              :src="form.bg_pic.url"
              alt=""
              srcset=""
            />
            <img
              class="null-imgbox"
              v-if="!form.bg_pic.url"
              @click="
                openSelectMedia({ type: 'bg_pic', title: '选择词条背景' })
              "
              style="width: 400px"
              :src="require('@/assets/images/bgpic-icon.svg')"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div
          v-show="[104, 106, 108].indexOf(form.type) >= 0"
          class="le-form-item"
        >
          <div class="le-form-title">词条图片</div>
          <div class="le-form-main">
            <img
              v-if="form.main_pic.url"
              @click="
                openSelectMedia({ type: 'main_pic', title: '选择词条图片' })
              "
              style="height: 80px"
              :src="form.main_pic.url"
              alt=""
              srcset=""
            />
            <img
              class="null-imgbox"
              v-if="!form.main_pic.url"
              @click="
                openSelectMedia({ type: 'main_pic', title: '选择词条图片' })
              "
              style="width: 80px"
              :src="require('@/assets/images/singopic-icon.svg')"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div v-show="[105, 107].indexOf(form.type) >= 0" class="le-form-item">
          <div class="le-form-title">词条图片列表</div>
          <div class="le-form-main">
            <div
              v-if="form.list_pic.length > 0"
              class="flex-center-start list-imgbox-wrapper"
            >
              <div
                v-for="(item, index) in form.list_pic"
                :key="index"
                class="list-imgbox"
              >
                <div
                  @click="
                    form.list_pic.splice(index, 1),
                      form.list_picIds.splice(index, 1)
                  "
                  class="del-btn"
                >
                  删除
                </div>
                <img
                  @click="
                    openSelectMedia({
                      type: 'list_pic',
                      title: '选择词条图片列表',
                    })
                  "
                  style="width: 80px"
                  :src="item.url"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="list-imgbox">
                <img
                  class="null-imgbox"
                  v-if="form.list_pic.length > 0"
                  @click="
                    openSelectMedia({
                      type: 'list_pic',
                      title: '选择词条图片列表',
                    })
                  "
                  style="width: 80px"
                  :src="require('@/assets/images/add-icon.svg')"
                  alt=""
                  srcset=""
                />
              </div>
            </div>

            <img 
              class="null-imgbox"
              v-if="form.list_pic.length == 0"
              @click="
                openSelectMedia({ type: 'list_pic', title: '选择词条图片列表' })
              "
              style="width: 400px" 
              :src="require('@/assets/images/piclist-icon.svg')"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div style="width: 100%; margin: 50px 0px" class="flex-center-end">
          <el-button @click="id ? updated() : add()" type="small">{{
            id ? "立即修改" : "立即添加"
          }}</el-button>
        </div>
      </div>
    </main>
    <el-dialog
      class="dialog-detail"
      :close-on-click-modal="false"   :modal="false"      :append-to-body="true"
      :visible.sync="dialogSetMedia"
      width="1100px"
    >
      <svg @click="dialogSetMedia = false" class="icon icon-dialog-close"   style="left:calc(50% + 1100px/2 - 35px) ">
        <use xlink:href="#lefticon-error"></use>
      </svg>
      <media-box
        @selectFn="selectFn"
        :type="'image'"
        :title="nowCurTitle"
      ></media-box>
    </el-dialog>

    <el-dialog
      class="dialog-detail"
      :close-on-click-modal="false"   :modal="false"      :append-to-body="true"
      :visible.sync="dialogSetArticle"
      width="800px"
    >
      <svg @click="dialogSetArticle = false" class="icon icon-dialog-close"   style="left:calc(50% + 800px/2 - 35px) ">
        <use xlink:href="#lefticon-error"></use>
      </svg>
      <article-list @selectFn="setArticle" :type="'select'"></article-list>
    </el-dialog>
  </div>
</template>
 
<script>
import MediaBox from "@/components/custom/MediaBox.vue";
import ArticleList from "@/components/custom/ArticleList.vue";

export default {
  components: { MediaBox, ArticleList },
  props: {
    id: {
      default: "",
    },
  },
  data() {
    return {
      nowCurKey: "",
      nowCurTitle: "",
      dialogSetMedia: false,
      dialogSetArticle: false,
      form: {
        type: 101,
        title: "",
        info: "",
        link: "",
        articleId: "",
        main_picId: "",
        main_pic: {
          url: "",
          info: "",
        },
        orderNum: 1,
        list_pic: [],
        list_picIds: [],
        bg_picId: "",
        bg_pic: {
          url: "",
          info: "",
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    selectFn(media) {
      switch (this.nowCurKey) {
        case "main_pic":
          this.form.main_pic = {
            url: media.url,
            info: media.info,
          };
          this.form.main_picId = media._id;
          break;
        case "bg_pic":
          this.form.bg_pic = {
            url: media.url,
            info: media.info,
          };
          this.form.bg_picId = media._id;
          break;
        case "list_pic":
          this.form.list_pic.push({
            url: media.url,
            info: media.info,
          });
          this.form.list_picIds.push(media._id);
          break;

        default:
          break;
      }

      this.nowCurKey = "";
      this.nowCurTitle = "";
      this.dialogSetMedia = false;
    },
    setArticle(articleId) {
      this.form.articleId = articleId;
      this.dialogSetArticle = false;
    },
    openSelectMedia({ type, title }) {
      this.nowCurKey = type;
      this.nowCurTitle = title;
      this.dialogSetMedia = true;
    },
    clearArticleId() {
      this.form.articleId = "";
      this.$forceUpdate()
    },
    add() {
      this.$loading(true);
      var data = JSON.parse(JSON.stringify(this.form));
      delete data.main_pic;
      delete data.list_pic;
      delete data.bg_pic;
      this.$api.POST("/adm/HomeTag", data).then((res) => {
        this.$loading(false);
        if (res.code == 200) {
          this.$router.push("/HomeTag");
        }
      });
    },
    updated() {
      this.$loading(true);
      var data = JSON.parse(JSON.stringify(this.form));
      delete data.main_pic;
      delete data.list_pic;
      delete data.bg_pic;
      this.$api.PUT("/adm/HomeTag/" + this.id, data).then((res) => {
        this.$loading(false);
        if (res.code == 200) {
          this.$router.push("/HomeTag");
        }
      });
    },
  },
  created() {
    if (this.id) {
      this.$api.GET("/adm/HomeTag/" + this.id).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
        }
      });
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
main {
  width: 1000px;
  min-height: 90vh;
  margin-bottom: 50px;
  background: #fff;
}
.form {
  padding: 50px;
  .title {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .homeTag-icon {
    height: 32px;background: #fff;
  }
  .el-radio {
    height: 60px;
    opacity: 0.7;
  }
  .el-radio:hover {
    opacity: 1;
  }
  .is-checked {
    opacity: 1;
  }
  .form-right {
    margin-top: 80px;
    width: 900px;
  }
  .form-right {
    .null-imgbox {
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.25s;
    }
    .null-imgbox:hover {
      opacity: 1;
    }
  }
  .list-imgbox-wrapper {
    flex-wrap: wrap;
    .list-imgbox {
      position: relative;
      width: 80px;
      height: 80px;
      overflow: hidden;
      margin-right: 10px;
      border: 1px #eee solid;
      display: flex;
      align-items: center;
      justify-content: center;
      .del-btn {
        position: absolute;
        right: 0px;
        top: 0px;
        background: #000;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>