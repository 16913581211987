<template>
  <div class="wrapper">
    <main class="form">
      <div class="page_title">{{ id ? "更新文章" : "发布文章" }}</div>
      <div class="le-form-item">
        <div class="le-form-title">选择文章类型</div>
        <div class="le-form-main">
          <div class="flex-col-start-start">
            <el-radio v-model="form.type" style="margin-top: 10px" :label="101"
              >完整文章页面
            </el-radio>
            <el-radio v-model="form.type" style="margin-top: 10px" :label="102"
              >不完整文章页面
            </el-radio>
            <el-radio v-model="form.type" style="margin-top: 10px" :label="103"
              >瀑布流页面
            </el-radio>
          </div>
        </div>
      </div>
      <div @click="dialogSetTag = true" class="setTag-btn">设置跳转链接</div>
      <div
        v-if="form.type !== 103"
        class="form-select-box flex-col-start-start"
      >
        <div class="title">选择组件</div>

        <draggable
          class="scrollWrapper2"
          :list="selectBox"
          @end="datadragEnd"
          :no-transition-on-drag="true"
          :group="{ name: 'acticle', pull: 'clone', put: false }"
        >
          <div
            v-for="(item, index) in selectBox"
            @click="addContentBox(index)"
            :key="index"
          >
            <img
              :src="
                require('@/assets/images/article-icon-' + item.type + '.svg')
              "
              alt="二级标题"
              srcset=""
            />
          </div>
        </draggable>
      </div>
      <div @click="id ? updated() : add()" class="add-btn">
        {{ id ? "更新文章" : "发布文章" }}
      </div>
      <div class="form-main">
        <div class="le-form-item">
          <div class="le-form-title">一级标题</div>
          <div class="le-form-main">
            <div class="input-check-box">
              <el-input
                :rows="2"
                autosize
                type="textarea"
                class="title-100"
                placeholder="请设置标题"
                v-model="form.title"
              ></el-input>
            </div>
          </div>
        </div>
        <div v-if="form.type !== 103" class="content">
          <draggable
            v-model="form.content"
            group="acticle"
            :options="dragOptions"
          >
            <transition-group class="content-wrapper-scroll">
              <div
                v-for="(item, index) in form.content"
                v-show="item.type != 108"
                :key="'content' + index"
                class="content-wrapper"
                @click="isActive = index"
                :class="{ contentActive: isActive === index }"
              >
                <el-popconfirm
                  confirm-button-text="好的"
                  cancel-button-text="不用了"
                  icon="el-icon-info"
                  icon-color="red"
                  @confirm="form.content.splice(index, 1)"
                  title="确定删除吗？"
                >
                  <div slot="reference" class="del-btn">删除</div>
                </el-popconfirm>
                <div class="content-box content-101" v-if="item.type == 101">
                  <div class="tips">二级标题</div>
                  <el-input
                    @blur="isActive = ''"
                    class="title"
                    :rows="1"
                    autosize
                    type="textarea"
                    placeholder="二级标题"
                    v-model="item.title"
                  ></el-input>
                </div>
                <div class="content-box content-102" v-if="item.type == 102">
                  <div class="tips">三级标题</div>
                  <el-input
                    @blur="isActive = ''"
                    class="title"
                    :rows="2"
                    autosize
                    type="textarea"
                    placeholder="三级标题"
                    v-model="item.title"
                  ></el-input>
                </div>
                <div class="content-box content-103" v-if="item.type == 103">
                  <div class="tips">正文</div>

                  <el-input
                    @blur="isActive = ''"
                    class="content"
                    autosize
                    :rows="4"
                    placeholder="这是正文，你可以在右上角选择你的个性化组件拖动至这里。"
                    type="textarea"
                    v-model="item.content"
                  ></el-input>
                </div>
                <div class="content-box content-104" v-if="item.type == 104">
                  <div class="tips">引述</div>
                  <el-input
                    @blur="isActive = ''"
                    class="quote"
                    :rows="2"
                    autosize
                    type="textarea"
                    placeholder="这是引述，就是在说话或写作中引用现成的话，以表达自己思想感情的修辞方法。引用可分为明引和暗引两种。"
                    v-model="item.quote"
                  ></el-input>
                </div>
                <div class="content-box content-105" v-if="item.type == 105">
                  <div class="tips">单个图片</div>
                  <img
                    :src="require('@/assets/images/article-icon-105.svg')"
                    @click="
                      openSelectMedia({
                        type: 'main_pic',
                        title: '选择图片',
                        index: index,
                      })
                    "
                    v-if="!item.main_pic"
                    alt=""
                    srcset=""
                  />
                  <div v-if="item.main_pic" class="flex-end-start">
                    <img :src="item.main_pic.url" alt="" srcset="" />
                    <div class="info">{{ item.main_pic.info }}</div>
                  </div>
                </div>
                <div class="content-box content-109" v-if="item.type == 109">
                  <div class="tips">大图板块</div>
                  <img
                    :src="require('@/assets/images/article-icon-109.svg')"
                    @click="
                      openSelectMedia({
                        type: 'main_pic',
                        title: '选择图片',
                        index: index,
                      })
                    "
                    v-if="!item.main_pic"
                    alt=""
                    srcset=""
                  />
                  <div v-if="item.main_pic" class="flex-end-start">
                    <img :src="item.main_pic.url" alt="" srcset="" />
                  </div>
                </div>
                <div class="content-box content-106" v-if="item.type == 106">
                  <div class="tips">图片列表</div>
                  <!-- 没有图片 -->

                  <img
                    :src="require('@/assets/images/article-icon-106.svg')"
                    v-if="item.list_pic.length == 0"
                    @click="
                      openSelectMedia({
                        type: 'list_pic',
                        title: '选择图片列表',
                        index: index,
                      })
                    "
                    alt=""
                    srcset=""
                  />

                  <!-- 显示图片 -->
                  <div v-if="item.list_pic.length > 0" class="list-pic">
                    <draggable
                      :no-transition-on-drag="true"
                      :list="item.list_pic"
                      :options="{
                        animation: 120,
                        scroll: true,
                      }"
                    >
                      <transition-group class="flex-center-start">
                        <div
                          class="flex-center-start list-pic-box"
                          v-for="(listPic, index) in item.list_pic"
                          :key="'image' + index"
                        >
                          <div
                            class="del-btn"
                            @click="item.list_pic.splice(index, 1)"
                          >
                            删除
                          </div>
                          <img alt="" :src="listPic.url" srcset="" />
                        </div>
                      </transition-group>
                    </draggable>
                    <img
                      class="null-imgbox"
                      @click="
                        openSelectMedia({
                          type: 'list_pic',
                          title: '选择图片列表',
                          index: index,
                        })
                      "
                      style="height: 130px"
                      :src="require('@/assets/images/add-icon.svg')"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <div class="content-box content-107" v-if="item.type == 107">
                  <div class="tips">视频列表</div>
                  <img
                    :src="require('@/assets/images/article-icon-107.svg')"
                    @click="
                      openSelectMedia({
                        type: 'list_video',
                        title: '选择视频列表',
                        index: index,
                      })
                    "
                    v-if="item.list_video.length == 0"
                    alt=""
                    srcset=""
                  />
                  <div v-if="item.list_video.length > 0" class="list-video">
                    <draggable
                      :no-transition-on-drag="true"
                      :list="item.list_video"
                      :options="{
                        animation: 120,
                        scroll: true,
                      }"
                    >
                      <transition-group class="flex-center-start">
                        <div
                          class="flex-center-start list-video-box"
                          v-for="(listVideo, index) in item.list_video"
                          :key="'video' + index"
                        >
                          <div
                            class="del-btn"
                            @click="item.list_video.splice(index, 1)"
                          >
                            删除
                          </div>
                          <video :src="listVideo.url" controls></video>
                        </div>
                      </transition-group>
                    </draggable>

                    <img
                      class="null-imgbox"
                      @click="
                        openSelectMedia({
                          type: 'list_video',
                          title: '选择视频列表',
                          index: index,
                        })
                      "
                      style="height: 130px"
                      :src="require('@/assets/images/add-icon.svg')"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>

        <div v-if="form.type == 103" class="content-108">
          <div class="content-108-wrapper">
            <img
              class="null-cover"
              v-if="content_108[0].list_pic.length == 0"
              @click="
                openSelectMedia({
                  type: 'list_pic_108',
                  title: '选择瀑布流图片列表',
                  index: index,
                })
              "
              :src="require('@/assets/images/article-icon-108.svg')"
              srcset=""
            />
            <draggable
              :no-transition-on-drag="true"
              :list="content_108[0].list_pic"
              :options="{
                animation: 120,
                scroll: true,
              }"
            >
              <transition-group class="flex-center-start content-108-box">
                <div
                  @click="
                    openSelectMedia({
                      type: 'list_pic_108',
                      title: '选择瀑布流图片列表',
                      index: index,
                    })
                  "
                  v-for="(item, index) in content_108[0].list_pic"
                  :key="'content-108' + index"
                  class="img-box"
                >
                  <img :src="item.url" alt="" srcset="" />
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </main>
    <el-dialog
      class="dialog-detail"
      :close-on-click-modal="false"
      :visible.sync="dialogSetMedia"
      :modal="false"
      :append-to-body="true"
      width="1100px"
    >
      <svg
        @click="dialogSetMedia = false"
        class="icon icon-dialog-close"
        style="left: calc(50% + 1100px / 2 - 35px)"
      >
        <use xlink:href="#lefticon-error"></use>
      </svg>
      <media-box
        :isSelectIds.sync="nowCurIsSelectIds"
        v-if="dialogSetMedia"
        @selectFn="selectFn"
        :type="nowCurKey == 'list_video' ? 'video' : 'image'"
        :title="nowCurTitle"
      ></media-box>
    </el-dialog>
    <el-dialog
      class="dialog-detail"
      :close-on-click-modal="false"
      :visible.sync="dialogSetTag"
      :modal="false"
      :append-to-body="true"
      width="800px"
    >
      <div style="height: 500px">
        <svg
          @click="dialogSetTag = false"
          class="icon icon-dialog-close"
          style="left: calc(50% + 800px / 2 - 35px)"
        >
          <use xlink:href="#lefticon-error"></use>
        </svg>
        <div
          v-for="(tag, i) in tagsList"
          :key="`t${i}`"
          class="setTag-item"
          style="margin-top: 20px"
        >
          <div style="width: 35%">
            标题 <el-input type="text" v-model="tag.title" disabled />
          </div>
          <div style="width: 65%; margin-left: 30px">
            链接
            <el-input type="text" v-model="tag.url" placeholder="输入链接" />
          </div>
        </div>
        <div v-if="tagsList.length == 0">暂无跳转链接</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import MediaBox from "@/components/custom/MediaBox.vue";

export default {
  components: { draggable, MediaBox },
  props: {
    id: {
      default: "",
    },
  },
  data() {
    return {
      nowCurKey: "",
      nowCurIndex: "",
      nowCurTitle: "",
      nowCurIsSelectIds: [],
      tagsList: [],
      backupTagsList: false,
      tagsIds: [],
      dialogSetTag: false,
      dialogSetMedia: false,
      dragOptions: {
        animation: 120,
        scroll: true,
        group: "sortlist",
        ghostClass: "ghost-style",
      },
      isActive: "",
      form: {
        type: 101,
        content: [
          {
            type: 101,
            title: "",
          },
          {
            type: 103,
            content: "",
          },
        ],
        title: "",
      },
      content_108: [
        {
          type: 108,
          list_pic: [],
          list_picIds: [],
        },
      ],
      selectBox: [
        {
          type: 101,
          title: "",
        },
        {
          type: 102,
          title: "",
        },
        {
          type: 103,
          content: "",
        },
        {
          type: 104,
          quote: "",
        },
        {
          type: 105,
          main_pic: "",
          main_picId: "",
        },
        {
          type: 109,
          main_pic: "",
          main_picId: "",
        },
        {
          type: 106,
          list_pic: [],
          list_picIds: [],
        },
        {
          type: 107,
          list_video: [],
          list_videoIds: [],
        },
      ],
      timer: "",
    };
  },
  watch: {
    "form.content": {
      handler() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.formatTags();
        }, 500);
      },
      immediate: true,
      deep: true,
    },
    "form.title": {
      handler() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.formatTags();
        }, 500);
      },
      immediate: true,
      deep: true,
    },
    "form.quote": {
      handler() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.formatTags();
        }, 500);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {},
  methods: {
    formatTags() {
      var content = this.form.content;
      var pDiv = document.createElement("div");
      if (content && content.length > 0) {
        let typeArr = [101, 102, 103, 104];
        let typeObj = {
          101: "title",
          102: "title",
          103: "content",
          104: "quote",
        };
        content.forEach((e) => {
          if (typeArr.includes(e.type)) {
            pDiv.innerHTML += e[typeObj[e.type]];
          }
        });
      }
      if (!this.backupTagsList) {
        this.formatTags();
      }
      if (this.tagsList.length > 0) {
        this.backupTagsList = JSON.parse(JSON.stringify(this.tagsList));
      }

      this.tagsList = [];
      let arr = Array.from(pDiv.getElementsByTagName("a"));
      arr.forEach((e) => {
        if (this.backupTagsList) {
          var tag = this.backupTagsList.filter((t) => t.title == e.innerText);
        }

        this.tagsList.push({
          info: "",
          title: e.innerText,
          url: tag && tag[0] && tag[0].url,
          type: 201,
        });
      });
    },
    selectFn(media) {
      var indexId = -1;
      switch (this.nowCurKey) {
        case "main_pic":
          this.form.content[this.nowCurIndex].main_pic = {
            _id: media._id,
            url: media.url,
            info: media.info,
          };
          break;
        case "list_video":
          indexId = this.form.content[this.nowCurIndex].list_video
            .map((list_video) => list_video._id)
            .indexOf(media._id);
          if (indexId >= 0) {
            this.form.content[this.nowCurIndex].list_video.splice(indexId, 1);
          } else {
            this.form.content[this.nowCurIndex].list_video.push({
              _id: media._id,
              url: media.url,
              info: media.info,
            });
          }
          this.nowCurIsSelectIds = this.form.content[
            this.nowCurIndex
          ].list_video.map((list_video) => list_video._id);
          break;
        case "list_pic":
          indexId = this.form.content[this.nowCurIndex].list_pic
            .map((list_pic) => list_pic._id)
            .indexOf(media._id);
          if (indexId >= 0) {
            this.form.content[this.nowCurIndex].list_pic.splice(indexId, 1);
          } else {
            this.form.content[this.nowCurIndex].list_pic.push({
              _id: media._id,
              url: media.url,
              info: media.info,
            });
          }
          this.nowCurIsSelectIds = this.form.content[
            this.nowCurIndex
          ].list_pic.map((list_pic) => list_pic._id);
          break;
        case "list_pic_108":
          indexId = this.content_108[0].list_pic
            .map((list_pic) => list_pic._id)
            .indexOf(media._id);

          if (indexId >= 0) {
            this.content_108[0].list_pic.splice(indexId, 1);
          } else {
            this.content_108[0].list_pic.push({
              _id: media._id,
              url: media.url,
              info: media.info,
            });
          }

          this.nowCurIsSelectIds = this.content_108[0].list_pic.map(
            (list_pic) => list_pic._id
          );
          break;
        default:
          break;
      }
      if (this.nowCurKey == "main_pic") {
        this.dialogSetMedia = false;
        this.nowCurKey = "";
        this.nowCurTitle = "";
        this.nowCurIndex = "";
      }
    },
    addContentBox(index) {
      this.form.content.push(JSON.parse(JSON.stringify(this.selectBox[index])));
    },
    openSelectMedia({ type, title, index }) {
      this.nowCurKey = type;
      this.nowCurTitle = title;
      this.nowCurIndex = index;
      this.nowCurIsSelectIds = [];
      switch (this.nowCurKey) {
        case "list_video":
          this.nowCurIsSelectIds = this.form.content[
            this.nowCurIndex
          ].list_video.map((list_video) => list_video._id);
          break;
        case "list_pic":
          this.nowCurIsSelectIds = this.form.content[
            this.nowCurIndex
          ].list_pic.map((list_pic) => list_pic._id);
          break;
        case "list_pic_108":
          this.nowCurIsSelectIds = this.content_108[0].list_pic.map(
            (list_pic) => list_pic._id
          );
          break;
        default:
          break;
      }

      this.dialogSetMedia = true;
    },
    // 拖拽位置结束
    datadragEnd() {
      this.form = JSON.parse(JSON.stringify(this.form));
    },
    add() {
      this.$loading(true);
      var data = JSON.parse(JSON.stringify(this.form));
      if (data.type == 103) {
        data.content = this.content_108;
      }

      data.content.forEach((content) => {
        if (content.type == 105) {
          content.main_picId = content.main_pic._id;
          delete content.main_pic;
        } else if (content.type == 106) {
          content.list_picIds = content.list_pic.map(
            (list_pic) => list_pic._id
          );
          delete content.list_pic;
        } else if (content.type == 107) {
          content.list_videoIds = content.list_video.map(
            (list_video) => list_video._id
          );
          delete content.list_video;
        } else if (content.type == 108) {
          content.list_picIds = content.list_pic.map(
            (list_pic) => list_pic._id
          );
          delete content.list_pic;
        }
      });

      this.$api.POST("/adm/article", data).then((res) => {
        this.$loading(false);
        if (res.code == 200) {
          this.$notify({
            title: `保存成功`,
            type: "success",
          });
          this.saveArticleTag(res.data._id);

          this.$router.push("/Article");
        }
      });
    },
    updated() {
      var data = JSON.parse(JSON.stringify(this.form));

      if (data.type == 103) {
        data.content = this.content_108;
      }

      data.content.forEach((content) => {
        if (content.type == 105) {
          content.main_picId = content.main_pic._id;
          delete content.main_pic;
        } else if (content.type == 109) {
          content.main_picId = content.main_pic._id;
          delete content.main_pic;
        } else if (content.type == 106) {
          content.list_picIds = content.list_pic.map(
            (list_pic) => list_pic._id
          );
          delete content.list_pic;
        } else if (content.type == 107) {
          content.list_videoIds = content.list_video.map(
            (list_video) => list_video._id
          );
          delete content.list_video;
        } else if (content.type == 108) {
          content.list_picIds = content.list_pic.map(
            (list_pic) => list_pic._id
          );
          delete content.list_pic;
        }
      });

      this.$api.PUT("/adm/article/" + this.id, data).then((res) => {
        if (res.code == 200) {
          this.saveArticleTag(this.id);
          this.$notify({
            title: `保存成功`,
            type: "success",
          });
          this.$router.push("/Article");
        }
      });
    },

    // 保存注释
    async saveArticleTag(id) {
      let tagsIds = await Promise.all(
        this.tagsList.map((e) => {
          return this.addTag(e);
        })
      );
      await this.$api
        .PUT("/adm/article/" + id, {
          tagsIds: tagsIds,
        })
        .then(async (res) => {
          if (res.code != 200) {
            this.$notify({
              title: `保存注释失败`,
              type: "success",
            });
          } else {
            this.$notify({
              title: `保存注释成功`,
              type: "success",
            });
            if (this.form.tags) {
              await Promise.all(
                this.form.tags.map((e) => {
                  return this.delOldTag(e._id);
                })
              );
            }

            this.$emit("reloadData");
          }
        });
    },
    // 添加注释
    addTag(e) {
      return new Promise((resolve) => {
        this.$api.POST("/adm/tag", e).then((res) => {
          if (res.code != 200) {
            this.$notify({
              title: `添加失败`,
              type: "error",
            });
            resolve(null);
          } else {
            resolve(res.data._id);
          }
        });
      });
    },
    // 删除旧注释
    delOldTag(id) {
      return new Promise((resolve) => {
        this.$api.DELETE("/adm/tag/" + id).then((res) => {
          if (res.code != 200) {
            resolve(null);
          } else {
            resolve(res.data._id);
          }
        });
      });
    },
  },
  created() {
    if (this.id) {
      this.$api.GET("/adm/article/" + this.id).then((res) => {
        if (res.code == 200) {
          this.form = res.data;

          if (this.form.type == 103) {
            this.content_108 = this.form.content;
            this.form.content = [
              {
                title: "请设置标题",
                type: 101,
              },
            ];
          }
          if (this.form.tags) {
            this.backupTagsList = this.form.tags;
          }
        }
      });
    }
  },
  mounted() {},
};
</script>
<style>
.form-main .title-100 .el-textarea__inner {
  min-height: 100px !important;
  font-size: 50px !important;
  height: 100px;
  line-height: 100px;
  color: #17bbf0;
  padding-left: 25px;
  border: 2px #eee solid;
  font-family: "Noto Serif SC";
  font-weight: 900;
  background: #f2f2f2;
}

.content-101 .title .el-textarea__inner {
  font-weight: 900;
  font-family: "Noto Serif SC";
  font-size: 40px;
}
.content-102 .title .el-textarea__inner {
  font-weight: 900;
  font-family: "Noto Serif SC";
  font-size: 20px;
}
.content-103 .content .el-textarea__inner {
  font-size: 20px;
  font-family: "Noto Serif SC";
  font-weight: lighter;
}
.content-104 .quote .el-textarea__inner {
  font-size: 20px;
  font-family: "Noto Serif SC";
  font-weight: lighter;
  color: #919191;
}

.content-box .el-textarea__inner {
  border: none !important;
  background: none;
}
.contentActive .el-textarea__inner {
  background: #fff;
}
</style>
<style lang="scss" scoped>
main {
  width: 1000px;
  min-height: 90vh;
  margin-bottom: 50px;
  background: #fff;
}
.form::-webkit-scrollbar {
  width: 20px !important;
  background: #eee;
  cursor: pointer !important;
}
.setTag-item {
  display: flex;
}
.form {
  padding: 50px;
  margin-left: -200px;
  max-height: 85vh;
  overflow-y: scroll;
  box-shadow: 0px 0px 100px #eeebeb;

  .form-select-box {
    position: absolute;
    width: 180px;
    padding: 5px;
    top: 60px;
    right: -200px;
    z-index: 99;
    transition: all 0.5s;
    overflow-y: scroll;
    background: #fff;
    box-shadow: 0px 0px 100px #eeebeb;
    .title {
      font-weight: bold;
      margin: 10px;
    }
    div {
      box-sizing: border-box;
    }
    img {
      width: 100%;
      cursor: pointer;
      border: 2px #eee solid;
    }
    img:hover {
      border: 2px #333 solid;
      width: 100%;
      margin-bottom: 5px;
    }
    img:last-child {
      margin-bottom: 0px;
    }
  }

  .setTag-btn {
    position: absolute;
    bottom: 50px;
    right: -200px;
    z-index: 999;
    width: 180px;
    color: #fff;
    padding: 5px;
    text-align: center;
    background: #000;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s;
  }
  .setTag-btn:hover {
    background: #333;
  }
  .setTag-btn:active {
    background: #000;
  }
  .add-btn {
    position: absolute;
    right: -200px;
    top: 0px;
    width: 180px;
    padding: 5px;
    z-index: 99;
    font-size: 20px;
    padding: 5px 20px;
    font-weight: bold;
    text-align: center;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 100px #eeebeb;
    cursor: pointer;
  }
  .content-wrapper {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px #eee solid;
    background: #f2f2f2;
    cursor: move;
    .del-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      background: #000;
      color: #fff;
      cursor: pointer;
    }

    .tips {
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 12px;
      color: #999;
    }
    img {
      cursor: pointer;
    }
    .content-101 {
      .title .el-textarea__inner {
        font-size: 50px !important;
      }
    }
    .content-102 {
      .title {
        font-size: 30px;
      }
    }
    .content-103 {
      .content {
        font-size: 30px;
      }
    }
    .content-104 {
      .quote {
        font-size: 30px;
      }
    }

    .content-105 {
      img {
        max-width: 300px;
        max-height: 500px;
      }
      .info {
        margin-left: 20px;
        font-family: "Noto Serif SC";
        font-size: 20px;
        font-weight: lighter;
        color: #919191;
      }
    }
    .content-109 {
      img {
        width: 100%;
        height: auto;
      }
    }
    .content-106 {
      background: #17bbf0;
      .list-pic::-webkit-scrollbar {
        width: 0 !important;
      }
      .list-pic::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      .list-pic {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 10px 10px 10px 30px;
        height: 150px;
        overflow-x: scroll;
        overflow-y: hidden;
        img {
          height: 130px;
          margin-right: 10px;
          width: auto;
        }
      }
      .list-pic-box {
        position: relative;
        .del-btn {
          position: absolute;
          right: 10px;
          top: 0px;
          background: #000;
          color: #fff;
          cursor: pointer;
          z-index: 99;
        }
      }
    }
    .content-107 {
      background: #17bbf0;
      .list-video::-webkit-scrollbar {
        width: 0 !important;
      }
      .list-video::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      .list-video {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 10px 10px 10px 30px;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 150px;

        video {
          height: 130px;
          cursor: pointer;
          margin-right: 10px;
          width: auto;
        }
      }
      .list-video-box {
        position: relative;
        .del-btn {
          position: absolute;
          right: 10px;
          top: 0px;
          background: #000;
          color: #fff;
          cursor: pointer;
          z-index: 99;
        }
      }
    }
    .el-textarea__inner {
      border: none !important;
    }
  }
  .content-box:hover {
    .el-textarea__inner {
      border: 2px #000 solid;
    }
  }
  // .form-select-box:hover {
  //   max-height: 700px;
  // }
}

.ghost-style {
  img {
    max-width: 500px;
    max-height: 800px;
  }
  height: auto;
  margin-bottom: 30px;
  overflow: hidden;
}

.content-108 {
  display: flex;
  flex-wrap: wrap;

  .content-108-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .content-108-box {
      flex-wrap: wrap;
    }
    .null-cover {
      width: 100%;
      height: auto;
    }
    .img-box {
      width: 160px;
      height: 160px;
      margin: 10px;
      border: 2px #eee solid;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 156px;
        max-height: 156px;
      }
    }
  }
}
</style>
